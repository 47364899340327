const lodash = require('lodash');
export const TB = {
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
  TABLET: 'Tablet',
  ALL: 'All',
  IOS: 'iOS',
  ANDROID: 'Android',
  MACOS: 'Mac OS X',
  LINUX: 'Linux',
  WINDOWS: 'Windows',
  DEFAULT_BUDGET: 10,
  DEFAULT_BID: 0.05,
  DEFAULT_DESKTOP_BID: 0.08,
  DEFAULT_IOS_BID: 0.04,
  DEFAULT_ANDROID_BID: 0.06,
  DEFAULT_DESKTOP_CONVERSION: 1045088,
  DEFAULT_IOS_CONVERSION: 1045073,
  DEFAULT_ANDROID_CONVERSION: 1045081,
  MIN_BID: 0.001,
  MAX_BID: 0.4,
  MAX_CAMP_NAME_LENGTH: 40,
  MAX_WARN_BID: 0.2,
  SMARTPHONE_ASPECT_RATIO: 1,
  DESKTOP_ASPECT_RATIO: 1.78,
  KEY_ARTICLE: 'article',
  KEY_DEVICE: 'device',
  KEY_OS: 'os',
  KEY_OS_CHOICES: 'os_choices',
  KEY_BID_TYPE: 'bid_type',
  KEY_BID_STRATEGY: 'bid_strategy',
  KEY_BRANDING_TEXT: 'branding_text',
  KEY_BUDGET: 'daily_cap',
  KEY_COUNTRY: 'country',
  KEY_CREATIVE_GROUPS: 'creativeGroups',
  KEY_CREATOR: 'campaignCreator',
  KEY_DEFAULT_BID: 'defaultBid',
  KEY_GEO_DUPLICATION_TYPE: 'geoDuplicationType',
  KEY_MARKETING_OBJECTIVE: 'marketing_objective',
  KEY_SPENDING_LIMIT_MODEL: 'spending_limit_model',
  KEY_SITE: 'site',
  KEY_BIDS: 'bids',
  KEY_PREFIX: 'prefix',
  KEY_QUERY_PARAMETERS: 'qs',
  MIN_WIDTH: 600,
  MIN_HEIGHT: 400,
  MAX_TEXT_CHARS: 100,
  MAX_DESCRIPTION_CHARS: 250,
  ALLOWED_VERSIONS: ['shz', 'apo', 'oct', 'tbl', 'bra', 'wzm', 'jsn'],
  SITE_ALLOWED_VERSIONS: {
    tup: ['shz', 'apo', 'oct', 'tbl', 'mim'],
    nmi: ['shz', 'apo', 'oct', 'tbl', 'mim'],
    spn: ['spn', 'shz'],
    esc: ['esc'],
    tdw: ['shz'],
    yrb: ['shz'],
    sds: ['shz'],
    fin: ['shz', 'oct'],
    tps: ['shz']
  },
  EXCLUDE_SITES: ['cas'],
  BID_STEP: 0.01,
  DEFAULT_VERSION: ['shz'],
  MSN_SAFE: 'MSN_SAFE',
  MSNSAFE: 'MSNSAFE',
  API_DESKTOP: 'DESK',
  API_MOBILE: 'PHON',
  API_TABLET: 'TBLT',
  SHINEZ_NETWORK: 'shinez-network',
  SHINEZ_SEARCH_NETWORK: 'shinez-serachnetwork',
  NMI: 'nmi',
  DUPLICATE_WITH_DATA_SEGMENTS: 'Duplicate with data segments',
  METRIC_OPTIONS: ['Spent', 'CTR'],
  KEY_METRIC: 'metric',
  KEY_BROWSER: 'browser',
  KEY_CA: 'customAudiences',
  KEY_CONVERSION_ID: 'conversionId',
  KEY_USE_DUPLICATION_API: 'use_duplication_api',
  KEY_ORIGINAL_SOURCE_DATA: 'original_source_data',
  KEY_OS_DUPLICATION_TYPE: 'osDuplicationType',
  KEY_DEVICE_DUPLICATION_TYPE: 'deviceDuplicationType',
  KEY_STATUS: 'status',
  STATUS: [
    { label: 'Active', value: 'active' },
    { label: 'Paused', value: 'paused' }
  ],
  CTA_VALUES: [
    {
      label: 'Book Now',
      value: 'BOOK_NOW'
    },
    {
      label: 'Buy Now',
      value: 'BUY_NOW'
    },
    {
      label: 'Click Here',
      value: 'CLICK_HERE'
    },
    {
      label: 'Download',
      value: 'DOWNLOAD'
    },
    {
      label: 'Get Offer',
      value: 'GET_OFFER'
    },
    {
      label: 'Get Quote',
      value: 'GET_QUOTE'
    },
    {
      label: 'Install Now',
      value: 'INSTALL_NOW'
    },
    {
      label: 'Learn More',
      value: 'LEARN_MORE'
    },
    {
      label: 'Play now',
      value: 'PLAY_NOW'
    },
    {
      label: 'Read More',
      value: 'READ_MORE'
    },
    {
      label: 'Saerch Now',
      value: 'SEARCH_NOW'
    },
    {
      label: 'Shop Now',
      value: 'SHOP_NOW'
    },
    {
      label: 'Sign Up',
      value: 'SIGN_UP'
    },
    {
      label: 'Try Now',
      value: 'TRY_NOW'
    },
    {
      label: 'Watch Now',
      value: 'WATCH_NOW'
    },
    {
      label: 'None',
      value: 'NONE'
    }
  ],

  BROWSERS: [
    {
      label: 'All',
      value: 'ALL'
    },
    {
      label: 'Chrome',
      value: 'Chrome'
    },
    {
      label: 'Safari',
      value: 'Safari'
    },
    {
      label: 'Facebook In-App Browser',
      value: 'Facebook In-App Browser'
    },
    {
      label: 'Edge',
      value: 'Edge'
    },
    {
      label: 'WebView',
      value: 'WebView'
    },
    {
      label: 'Chromium',
      value: 'Chromium'
    },
    {
      label: 'Firefox',
      value: 'Firefox'
    },
    {
      label: 'Samsung Browser',
      value: 'Samsung Browser'
    },
    {
      label: 'Internet Explorer',
      value: 'Internet Explorer'
    },
    {
      label: 'Google Search App',
      value: 'Google Search App'
    },
    {
      label: 'Android App',
      value: 'Android App'
    },
    {
      label: 'Opera',
      value: 'Opera'
    },
    {
      label: 'MIUI Browser',
      value: 'MIUI Browser'
    },
    {
      label: 'Amazon Silk',
      value: 'Amazon Silk'
    },
    {
      label: 'UC Browser',
      value: 'UC Browser'
    },
    {
      label: 'Yandex',
      value: 'Yandex'
    }
  ],
  PREFIX_LIST: ['0o', 'o0', 'em', 'kb', 'z0'],
  OLD_ACCOUNTS: [
    'omg-sc',
    'fnc-ustablet-sc',
    'fnc-usmobileios-sc',
    'fnc-usmobileadr-sc',
    'fnc-usdesktop-sc',
    'everydaykoalad',
    'everydaykoalam',
    'everydaykoalat',
    'sc-tsndesktop',
    'sc-tsnmobile',
    'sc-c4amobile',
    'sc-c4adesktop',
    'spb-usmobileadr-sc',
    'spb-usdesktop-sc',
    'spb-international',
    'spb-ustablet-sc',
    'spb-usmobileios-sc',
    'fnc-international-sc',
    'tdw-sc',
    'edmmobile-sc',
    'yssmobile-sc',
    'edmdesktop-sc',
    'tps-sc',
    'shinez-12up-ustablet-sc',
    'shinez-12up-usdesktop-sc',
    'shinez-12up-usmobileadr-sc',
    'shinez-12up-usmobileios-sc',
    'yss-mobile-sc',
    'mentalfloss-usdesktop-us',
    'mentalfloss-usmobileios-us',
    'mentalfloss-ustablet-us',
    'mentalfloss-usmobileadr-us',
    'hptmobile-sc',
    'mentalfloss-international-sc',
    'bts-usdesktop-sc',
    'bts-usmobileadr-sc',
    'bts-usmobileios-sc',
    'bts-ustablet-sc',
    'fih-ustablet-sc',
    'fih-international-sc',
    'trd-usdesktop-sc',
    'bts-international-sc',
    'fih-usdesktop-sc',
    'fih-usmobileadr-sc',
    'shinez-90min-ukdesktop-sc',
    'fih-usmobileios-sc',
    'shinez-90min-ukmobile-sc',
    'mentalfloss-uktablet-us',
    'dw-sc',
    'mentalfloss-cadesktop-us',
    'mentalfloss-ukdesktop-us',
    'mentalfloss-ukmobile-us',
    'mentalfloss-audesktop-us',
    'mentalfloss-camobile-us',
    'mentalfloss-catablet-us',
    'mentalfloss-aumobile-us',
    'mentalfloss-autablet-us',
    'hpt-usmobileios-sc',
    'hpt-usmobileadr-sc',
    'hpt-usdesktop-sc',
    'tpd-ustablet-sc',
    'tpd-international-sc',
    'hpt-ustablet-sc',
    'wpd-usdesktop-sc',
    'wpd-usmobileadr-sc',
    'wpd-usmobileios-sc',
    'yourdailysportfixd',
    'yourdailysportfixm',
    'yrb-sc',
    'mob-usdesktop-sc',
    'mdmusdesktop',
    'mob-usmobileadr-sc',
    'mob-usmobileios-sc',
    'mob-ustablet-sc',
    '90minbrmobileadr-sc',
    'trd-usmobileadr-sc',
    'mdminternational-sc',
    'trd-usmobileios-sc',
    'mdmustablet-sc',
    'shinezdtes-sc',
    'trd-ustablet-sc',
    'mdmusmobileios-sc',
    'shinezmes-sc',
    'trd-international-sc',
    '90minbrtablet-sc',
    '90minbrdesktop-sc',
    'trm-usdesktop-sc',
    '90minbrmobileios-sc',
    'mob-international-sc',
    'trm-ustablet-sc',
    'tpd-usdesktop-sc',
    'tpd-usmobileadr-sc',
    'trm-usmobileios-sc',
    'tpd-usmobileios-sc',
    'trm-usmobileadr-sc',
    'sol-usmobileios-sc',
    'sol-ustablet-sc',
    'sol-international-sc',
    'fdw-usdesktop-sc',
    'efu-usmobileadr-sc',
    'ofl-international-sc',
    '90minzamobile-sc',
    'efu-usmobileios-sc',
    '90minzatablet-sc',
    'efu-ustablet-sc',
    'sol-usdesktop-sc',
    'efu-international-sc',
    '90minzadesktop-sc',
    'sol-usmobileadr-sc',
    'fdw-international-sc',
    'tsw-usdesktop-sc',
    'tsw-usmobileadr-sc',
    'fdw-usmobileadr-sc',
    'fdw-usmobileios-sc',
    'shinezmus-sc',
    'fdw-ustablet-sc',
    'ttb-usmobileios-sc',
    'yoursportspott',
    'ttb-ustablet-sc',
    'yoursportspotd',
    'ttb-international-sc',
    'yoursportspotm',
    'edm-usdesktop-sc',
    'yourdailysportfixt',
    'mdmmobileadr-sc',
    'wpd-ustablet-sc',
    'wpd-international-sc',
    'ttb-usdesktop-sc',
    'ttb-usmobileadr-sc',
    'ofl-usmobileadr-sc',
    'ofl-usmobileios-sc',
    'efu-usdesktop-sc',
    'ofl-ustablet-sc',
    'edm-usmobileadr-sc',
    'edm-usmobileios-sc',
    'cooking4allcom',
    'edm-ustablet-sc',
    'edm-international-sc',
    'ofl-usdesktop-sc',
    'ourdailybaby',
    'hotpoptoday-m',
    'hotpoptoday-d',
    'sportzbonanzad',
    'travelermaster-t',
    'travelermasterm',
    'sportzbonanzam',
    'hotpoptoday-t',
    '90min-usdesktop-sc',
    'falafelandcaviard',
    'sportzbonanzat',
    '90min-ukdesktop-sc',
    '90min-ustablet-sc',
    'falafelandcaviart',
    '90min-usmobileios-sc',
    'falafelandcaviarm',
    '90min-usmobileadr-sc',
    'tsw-international-sc',
    'tsw-usmobileios-sc',
    'spn-sc',
    'travelermaster-d',
    'esc-sc',
    'edk-ustablet-sc',
    'yss-usdesktop-sc',
    'edk-ukdesktop-sc',
    'edk-usmobileios-sc',
    'edk-cadesktop-sc',
    'edk-camobile-sc',
    'edk-ukmobile-sc',
    'yss-usmobileadr-sc',
    'edk-uktablet-sc',
    'reg-sc',
    'edk-aumobile-sc',
    'edk-autablet-sc',
    'edk-catablet-sc',
    'yss-usmobileios-sc',
    'edk-audesktop-sc',
    'yss-ustablet-sc',
    'edk-internationalaccount-sc',
    'tdd-usmobileios-sc',
    'tsw-ustablet-sc',
    '90min-camobile-sc',
    '90min-cadesktop-sc',
    '90min-uktablet-sc',
    '90min-ukmobile-sc',
    '90min-autablet-sc',
    '90min-aumobile-sc',
    '90min-audesktop-sc',
    '90min-catablet-sc',
    'yds-usmobileadr-sc',
    'yds-usdesktop-sc',
    '90min-international-sc',
    'edk-usdesktop',
    'yds-international-sc',
    'edk-usmobileadr-sc',
    'yds-ustablet-sc',
    'yds-usmobileios-sc',
    'sds-sc',
    'oft-usdesktop-sc',
    'oft-usmobileadr-sc',
    'tdc-usmobileadr-sc',
    'tdc-usmobileios-sc',
    'tdc-ustablet-sc',
    'oft-usmobileios-sc',
    'mydailymagazinet',
    'oft-ustablet-sc',
    'mydailymagazinem',
    'oft-international-sc',
    'mydailymagazined',
    'tdc-usdesktop-sc',
    'yss-international-sc',
    'tdd-international-sc',
    'wtn-usdesktop-sc',
    'wtn-usmobileadr-sc',
    'tdc-international-sc',
    'shinez-sc',
    'tdd-usdesktop-sc',
    'tdd-usmobileadr-sc',
    'tdd-ustablet-sc',
    'wtn-usmobileios-sc',
    'wtn-ustablet-sc',
    'wtn-international-sc',
    'shinez-network',
    'shinez-sunglore-sc',
    'shinez-omgcheckitout',
    'beautifultrendstodaym',
    'beautifultrendstodayd',
    'foodisinthehouset',
    'foodisinthehousem',
    'foodisinthehoused',
    'beautifultrendstodayt',
    'mobispiritd',
    'travelerdreamst',
    'travelerdreamsm',
    'travelerdreamsd',
    '12up-international-sc',
    'toppoptodayd',
    'mobispiritt',
    'trm-international-sc',
    'mobispiritm',
    'shinez-hb',
    'ourfunnylittlesite',
    'shinez-90min-audesktop-sc',
    '5dwallpaper',
    'shinez-90min-aumobile-sc',
    'everydaymonkey',
    'shinez-90min-camobile-sc',
    'shinez-90min-catablet-sc',
    'thestarsworldwide',
    'shinez-90min-demobile-sc',
    'shinez-90min-detablet-sc',
    'shinez-90min-autablet-sc',
    'shinez-90min-dedesktop-sc',
    'ourfashiontrends',
    'watchingtvnow',
    'entertainmentforus',
    'wallpaperdata',
    'tettybetty',
    'thedancingcucumber',
    'thedailyday',
    'international90min-sc',
    'styleourlife',
    'toppoptodayt',
    'toppoptodaym',
    'hpt-international-sc',
    'shinez-90min-sc',
    'shinez-90min-usdesktop-sc',
    'shinez-90min-usmobileadr-sc',
    'shinez-12up-sc',
    'shinez-90min-uktablet-sc',
    'shinez-90min-cadesktop-sc',
    'shinez-90min-usmobileios-sc',
    'shinez-90min-ustablet-sc',
    'shinez-omgcheckitoutm',
    'shinez-omgcheckitoutes'
  ],
  S2S_CONVERSIONS: [
    {
      label: 'S2SR',
      value: 'S2SR'
    },
    {
      label: 'S2SP',
      value: 'S2SP'
    }
  ],
  BID_STRATEGY_LIST: [
    {
      label: 'Bid Control (CPC/CPM)',
      value: 'SMART'
    },
    {
      label: 'Max Conversions (CPA)',
      value: 'MAX_CONVERSIONS'
    }
  ],
  OBJECTIVES_LIST: [
    {
      label: 'Brand awareness',
      value: 'BRAND_AWARENESS'
    },
    {
      label: 'Leads generation',
      value: 'LEADS_GENERATION'
    },
    {
      label: 'Online purchases',
      value: 'ONLINE_PURCHASES'
    },
    {
      label: 'Drive website traffic',
      value: 'DRIVE_WEBSITE_TRAFFIC'
    },
    {
      label: 'Mobile app install',
      value: 'MOBILE_APP_INSTALL'
    }
  ],
  SPENDING_MODEL_LIST: [
    {
      label: 'Monthly flight',
      value: 'MONTHLY'
    },
    {
      label: 'Entire campaign',
      value: 'ENTIRE'
    },
    {
      label: 'None',
      value: 'NONE'
    }
  ],
  KEY_TIMES: 'cloneCount',
  NO_BID: 0
};

export const TB_OS = {
  [TB.MOBILE]: [TB.IOS, TB.ANDROID, TB.ALL],
  [TB.TABLET]: [TB.IOS, TB.ANDROID, TB.ALL],
  [TB.DESKTOP]: [TB.MACOS, TB.LINUX, TB.WINDOWS]
};

export const RC = {
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
  TABLET: 'Tablet',
  IOS: 'iOS',
  ANDROID: 'Android',
  MACOS: 'Mac OS X',
  LINUX: 'Linux',
  WINDOWS: 'Windows',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  STANDARD: 'Standard',
  AUTO: 'Auto',
  DEFAULT_BUDGET: 0,
  DEFAULT_BID: 0.01,
  DEFAULT_SITE: [],
  MIN_BID: 0.01,
  MAX_BID: 0.4,
  MIN_CONVERSION_BID: 0.001,
  MAX_CONVERSION_BID: 0.5,
  MAX_WARN_BID: 0.2,
  KEY_ARTICLE: 'article',
  KEY_DEVICE: 'device',
  KEY_OS: 'os',
  KEY_BID_TYPE: 'bid_type',
  KEY_BRANDING_TEXT: 'branding_text',
  KEY_BUDGET: 'budget',
  KEY_BUDGET_AMOUNT: 'budget_amount',
  KEY_CAMPAIGN_TYPE: 'campaign_type',
  KEY_COUNTRY: 'country',
  KEY_CREATIVE_GROUPS: 'creativeGroups',
  KEY_DEFAULT_BID: 'defaultBid',
  KEY_MARKETING_OBJECTIVE: 'marketing_objective',
  KEY_SPENDING_LIMIT_MODEL: 'spending_limit_model',
  KEY_SITE: 'site',
  KEY_STATUS: 'status',
  KEY_BIDS: 'bids',
  KEY_PREFIX: 'prefix',
  KEY_QUERY_PARAMETERS: 'qs',
  KEY_CONVERSION: 'conversion',
  KEY_CONVERSION_RATE: 'conversion_rate',
  KEY_CAMPAIGN_STOP_TIME: 'campaign_stop_time',
  MIN_WIDTH: 600,
  MIN_HEIGHT: 400,
  MAX_TEXT_CHARS: 500,
  ALLOWED_VERSIONS: ['shz', 'apo', 'oct', 'bra', 'wzm'],
  SITE_ALLOWED_VERSIONS: {
    tup: ['shz', 'apo', 'oct', 'mim'],
    nmi: ['shz', 'apo', 'oct', 'mim'],
    fin: ['shz', 'oct'],
    tps: ['shz']
  },
  EXCLUDE_SITES: ['rfg', 'cas'],
  DEFAULT_VERSION: ['shz'],
  BID_STEP: 0.01,
  BID_CONVERSION_STEP: 0.001,

  API_DESKTOP: '1',
  API_MOBILE: '2',
  API_TABLET: '3',
  API_ANDROID: '4',
  API_IOS: '5',
  API_WINDOWS: '6'
};

export const GA = {
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
  SMARTPHONE: 'Smartphone',
  PORTRAIT: 'Portrait',
  TABLET: 'Tablet',
  OTHER: 'Other',
  CONNECTED_TV: 'Connected_TV',
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  DEFAULT_BUDGET: 10,
  DEFAULT_BID: 0.05,
  DEFAULT_SITE: [],
  DEFAULT_DEVICE: ['Desktop'],
  DEFAULT_COUNTRY: ['us'],
  MIN_BID: 0.05,
  MAX_BID: 1,
  MAX_WARN_BID: 0.2,
  KEY_ARTICLE: 'article',
  KEY_FULL_CODE_NAME: 'fullCodeName',
  KEY_DEVICE: 'device',
  KEY_BRANDING_TEXT: 'branding_text',
  KEY_BUDGET: 'budget',
  KEY_BUDGET_TYPE: 'budgetType',
  KEY_CAMPAIGN_BIDDING_STRATEGY: 'campaignBiddingType',
  KEY_COUNTRY: 'country',
  KEY_CONVERSION_RULE: 'conversionRuleIds',
  KEY_CREATIVE_GROUPS: 'creativeGroups',
  KEY_CREATOR: 'campaignCreator',
  KEY_CAMPAIGN_TYPE: 'campaignType',
  KEY_SITE: 'site',
  KEY_STATUS: 'status',
  KEY_PREFIX: 'prefix',
  KEY_QUERY_PARAMETERS: 'qs',
  KEY_CAMPAIGN_START_TIME: 'campaignStartTime',
  KEY_CAMPAIGN_STOP_TIME: 'campaignStopTime',
  KEY_DESTINATION_ACCOUNT: 'destinationAccount',
  KEY_GEO_DUPLICATION_TYPE: 'geoDuplicationType',
  KEY_CAMPAIGN_CPA: 'campaignCPA',
  KEY_ADSET_CPC: 'adsetCPC',
  KEY_BIDS: 'bids',
  DEFAULT_DESTINATION_ACCOUNT: '',
  DEFAULT_CAMPAIGN_TYPE: 'DISPLAY',
  STATUS: [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Paused', value: 'PAUSED' }
  ],
  CAMPAIGN_TYPE: [
    {
      label: 'Display',
      value: 'DISPLAY'
    },
    {
      label: 'Performance max',
      value: 'PERFORMANCE_MAX'
    }
  ],
  CAMPAIGN_BIDDING_STRATEGY: [
    {
      label: 'Target CPA',
      value: 'TARGET_CPA'
    },
    {
      label: 'Manual CPC',
      value: 'MANUAL_CPC'
    },
    {
      label: 'Maximize conversions',
      value: 'MAXIMIZE_CONVERSIONS'
    },
    { label: 'Maximize coversions value', value: 'MAXIMIZE_CONVERSION_VALUE' }
  ],
  CAMPAIGN_CONVERSIONS: [
    { label: 'All - 0.08', value: 'All - 0.08' },
    { label: 'All - 0.07', value: 'All - 0.07' },
    { label: 'All - 0.1', value: 'All - 0.1' },
    { label: 'All - 0.09', value: 'All - 0.09' },
    { label: 'All - 0.02', value: 'All - 0.02' },
    { label: 'All - 0.03', value: 'All - 0.03' },
    { label: 'All - 0.01', value: 'All - 0.01' },
    { label: 'All - 0.04', value: 'All - 0.04' },
    { label: 'All - 0.05', value: 'All - 0.05' },
    { label: 'All - 0.06', value: 'All - 0.06' },
    { label: 'All - Values', value: 'All - Values' },
    { label: 'All - 0.11', value: 'All - 0.11' },
    { label: 'All - 0.12', value: 'All - 0.12' },
    { label: 'All - 0.15', value: 'All - 0.15' },
    { label: 'All - 0.14', value: 'All - 0.14' },
    { label: 'All - 0.13', value: 'All - 0.13' },
    { label: 'All - 0.24', value: 'All - 0.24' },
    { label: 'All - 0.2', value: 'All - 0.2' },
    { label: 'All - 0.29', value: 'All - 0.29' },
    { label: 'All - 0.18', value: 'All - 0.18' },
    { label: 'All - 0.27', value: 'All - 0.27' },
    { label: 'All - 0.17', value: 'All - 0.17' },
    { label: 'All - 0.19', value: 'All - 0.19' },
    { label: 'All - 0.21', value: 'All - 0.21' },
    { label: 'All - 0.25', value: 'All - 0.25' },
    { label: 'All - 0.16', value: 'All - 0.16' },
    { label: 'All - 0.28', value: 'All - 0.28' },
    { label: 'All - 0.22', value: 'All - 0.22' },
    { label: 'All - 0.23', value: 'All - 0.23' },
    { label: 'All - 0.26', value: 'All - 0.26' },
    { label: 'S2S - All Values', value: 'S2S - All Values' }
  ],
  KEY_CAMPAIGN_CONVERSION: 'campaignConversion',
  DEFAULT_CAMPAIGN_BIDDING_STRATEGY: 'TARGET_CPA',
  FULL_CODE_NAME: '',
  DEFAULT_ADSET_CPC: 0.5,
  DEFAULT_CAMPAIGN_CPA: 0.1,
  BUDGET_STEP: 100,
  ADSET_CPC_STEP: 0.01,
  MIN_WIDTH: 627,
  MIN_HEIGHT: 627,
  MIN_WIDTH_DESKTOP: 1200,
  MAX_IMAGE_FILESIZE: 2097152,
  SMARTPHONE_ASPECT_RATIO: 1,
  DESKTOP_ASPECT_RATIO: 1.9,
  PORTRAIT_ASPECT_RATIO: 0.8,
  MIN_WIDTH_PORTRAIT: 480,
  MIN_HEIGHT_PORTRAIT: 600,
  MIN_TITLES_AMOUNT: 3,
  MAX_TITLES_AMOUNT: 5,
  MIN_LONG_TITLE_AMOUNT: 1,
  MAX_LONG_TITLE_AMOUNT: 5,
  MAX_TEXTS_AMOUNT: 5,
  MAX_TITLE_CHARS: 30,
  MAX_SHORT_TITLE_CHARS: 15,
  MAX_SHORT_TEXT_CHARS: 30,
  MAX_LONG_TITLE_CHARS: 90,
  MAX_TEXT_CHARS: 90,
  ALLOWED_VERSIONS: ['shz', 'apo', 'oct', 'bra', 'one', 'wzm'],
  SITE_ALLOWED_VERSIONS: {
    tup: ['shz', 'apo', 'oct', 'mim'],
    nmi: ['shz', 'apo', 'oct', 'mim'],
    spn: ['spn', 'shz'],
    esc: ['esc'],
    tdw: ['shz'],
    yrb: ['shz'],
    sds: ['shz'],
    fin: ['shz', 'oct'],
    tps: ['shz']
  },
  EXCLUDE_SITES: [],
  DEFAULT_VERSION: ['shz'],
  BID_STEP: 0.01,
  CAMPAIGN_CPA_STEP: 0.01,
  CAMPAIGN_CPA: 0.01,
  DEVICE_LIST: [
    { label: 'Desktop', value: 'Desktop' },
    { label: 'Mobile', value: 'Mobile' },
    { label: 'Tablet', value: 'Tablet' },
    { label: 'Connected TV', value: 'Connected_TV' },
    { label: 'Other', value: 'Other' }
  ],
  BLOCK_WORDS_SITES: ['hpt', 'edk', 'tdc', 'yss', 'spb', 'ttb', 'tpd', 'tdc'],
  AD_IMAGE_LIMIT: 20,
  ARTICLE_BLOCK_B2B: {
    'disabled-b2b-all-all': 1,
    'disabled-b2b-ga-all': 1,
    'disabled-global-all-all': 1,
    'disabled-global-ga-all': 1
  },
  ARTICLE_BLOCK_SWAG: {
    'disabled-swag-all-all': 1,
    'disabled-swag-ga-all': 1,
    'disabled-global-all-all': 1,
    'disabled-global-ga-all': 1
  }
};

export const BD = {
  DESKTOP: 'Desktop',
  SMARTPHONE: 'Smartphone',
  IOS: 'iOS',
  ANDROID: 'Android',
  MACOS: 'Mac OS X',
  LINUX: 'Linux',
  WINDOWS: 'Windows',
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  ALL_BROWSERS: ['Safari', 'Chrome', 'Edge', 'Firefox'],
  BROWSERS: [
    { label: 'Safari', value: 'Safari' },
    { label: 'Chrome', value: 'Chrome' },
    { label: 'Edge', value: 'Edge' },
    { label: 'Firefox', value: 'Firefox' }
  ],
  DEVICES: [
    { label: 'Desktop', value: 'Desktop' },
    { label: 'Smartphone', value: 'Smartphone' },
    { label: 'iOS', value: 'iOS' },
    { label: 'Android', value: 'Android' }
  ],
  STATUS: [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Paused', value: 'PAUSED' }
  ],
  DEFAULT_BUDGET: 10,
  DEFAULT_BID: 0.06,
  DEFAULT_D_BID: 0.06,
  DEFAULT_A_BID: 0.04,
  DEFAULT_OS_BID: 0.03,
  DEFAULT_SITE: [],
  DEFAULT_BROWSER: [],
  DEFAULT_DEVICE: ['Desktop'],
  DEFAULT_COUNTRY: ['us'],
  DEFAULT_PRODUCT: 'Auto',
  MIN_BID: 0.01,
  MAX_BID: 5,
  MAX_WARN_BID: 0.2,
  KEY_ARTICLE: 'article',
  KEY_DEVICE: 'device',
  KEY_OS: 'os',
  KEY_BID_TYPE: 'bidType',
  KEY_BRANDING_TEXT: 'brandingText',
  KEY_BROWSER: 'browser',
  KEY_BUDGET: 'budget',
  KEY_BUDGET_TYPE: 'budgetType',
  KEY_BIDDING_STRATEGY: 'biddingStrategy',
  KEY_COUNTRY: 'country',
  KEY_COUNTRY_GROUP: 'countryGroup',
  KEY_COUNTRY_GROUP_TYPE: 'include',
  KEY_GEO_NAME: 'GeoName',
  KEY_CONVERSION_RULE: 'conversionRuleIds',
  KEY_CREATIVE_GROUPS: 'creativeGroups',
  KEY_CREATOR: 'campaignCreator',
  KEY_DEFAULT_BID: 'defaultBid',
  KEY_MARKETING_OBJECTIVE: 'marketing_objective',
  KEY_SPENDING_LIMIT_MODEL: 'spending_limit_model',
  KEY_SITE: 'site',
  KEY_STATUS: 'status',
  KEY_BIDS: 'bids',
  KEY_PREFIX: 'prefix',
  KEY_PRODUCT_TYPE: 'productType',
  KEY_OBJECTIVE: 'objective',
  PRODUCT_TYPE: [
    { label: 'E-commerce', value: 'E-commerce' },
    { label: 'Health & Fitness', value: 'Health & Fitness' },
    { label: 'Finance & Insurance', value: 'Finance & Insurance' },
    { label: 'Real Estate', value: 'Real Estate' },
    { label: 'Auto', value: 'Auto' },
    { label: 'Careers', value: 'Careers' },
    { label: 'Technology & Computing', value: 'Technology & Computing' },
    { label: 'Education', value: 'Education' },
    { label: 'Arts & Entertainment yes Style & Fashion', value: 'Arts & Entertainment yes Style & Fashion' },
    { label: 'Family & Parenting', value: 'Family & Parenting' },
    { label: 'Food', value: 'Food' },
    { label: 'Hobbies & Interests', value: 'Hobbies & Interests' },
    { label: 'Home & Garden', value: 'Home & Garden' },
    { label: 'Law, Gov’t & Politics', value: 'Law, Gov’t & Politics' },
    { label: 'News', value: 'News' },
    { label: 'Pets', value: 'Pets' },
    { label: 'Religion & Spirituality', value: 'Religion & Spirituality' },
    { label: 'Science', value: 'Science' },
    { label: 'Sports', value: 'Sports' },
    { label: 'Travel', value: 'Travel' },
    { label: 'Society', value: 'Society' },
    { label: 'Others', value: 'Others' }
  ],
  OBJECTIVE: [
    { label: 'Lead', value: 'lead' },
    { label: 'Conversions', value: 'conversions' },
    { label: 'Awareness', value: 'awareness' }
  ],
  KEY_TARGET_CPA: 'target_cpa',
  KEY_OPTIMIZATION_TYPE: 'optimization_type',
  OPTIMIZATION_TYPE: [
    { label: 'View Content', value: '1' },
    { label: 'App Install', value: '2' },
    { label: 'Complete Registration', value: '3' },
    { label: 'Add to Cart', value: '4' },
    { label: ' Add Payment Info', value: '5' },
    { label: 'Search', value: '6' },
    { label: 'Start Checkout', value: '7' },
    { label: 'Purchase', value: '8' },
    { label: 'Add to Wishlist', value: '9' },
    { label: 'Lead', value: '10' },
    { label: 'Other', value: '-1' }
  ],
  KEY_QUERY_PARAMETERS: 'qs',
  KEY_CAMPAIGN_START_TIME: 'startTime',
  KEY_CAMPAIGN_STOP_TIME: 'endTime',
  KEY_DESTINATION_ACCOUNT: 'destinationAccount',
  KEY_LANGUAGE: 'language',
  KEY_GEO_DUPLICATION_TYPE: 'geoDuplicationType',
  KEY_DAILY_CAP: 'dailyCap',
  KEY_SPEND_LIMIT: 'spendLimit',
  DEFAULT_DAILY_CAP: 20,
  DEFAULT_SPEND_LIMIT: 3000,
  DEFAULT_DESTINATION_ACCOUNT: 'Shinez',
  MIN_WIDTH: 627,
  MIN_HEIGHT: 627,
  MIN_WIDTH_DESKTOP: 1200,
  MAX_IMAGE_FILESIZE: 2097152,
  SMARTPHONE_ASPECT_RATIO: 1,
  DESKTOP_ASPECT_RATIO: 1.9,
  MAX_TITLE_CHARS: 80,
  ALLOWED_VERSIONS: ['bra', 'shz', 'wzm'],
  EXCLUDE_SITES: ['cas'],
  DEFAULT_VERSION: ['shz'],
  BID_STEP: 0.01,
  TARGET_CPA_STEP: 0.1,
  KEY_PACING: 'pacing',
  STATE_LIST: [
    { label: 'us', value: 'us' },
    { label: 'ca', value: 'ca' },
    { label: 'au', value: 'au' },
    { label: 'gb', value: 'gb' }
  ],
  API_DESKTOP: 'DESKTOP',
  API_MOBILE: 'SMARTPHONE ',
  API_TABLET: 'TABLET',
  MODIFIER_TASK: 'Modify bids',
  DUPLICATE_TASK_OLD: 'Duplicate (old)',
  MAX_CAMP_NAME_LENGTH: 40,
  ACCOUNT_ID: 359,
  MAX_ADSET_PART_LENGTH: 4,
  MAX_AD_PART_LENGTH: 3,
  DEFAULT_LANGUAGE: 'en',
  LANGUAGE_LIST: [
    { label: 'Arabic', value: 'ar' },
    { label: 'Danish', value: 'da' },
    { label: 'German', value: 'de' },
    { label: 'Greek', value: 'el' },
    { label: 'English', value: 'en' },
    { label: 'Spanish', value: 'es' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Korean', value: 'ko' },
    { label: 'Malay', value: 'ms' },
    { label: 'Dutch', value: 'nl' },
    { label: 'Norwegian', value: 'no' },
    { label: 'Potuguese', value: 'pt' },
    { label: 'Romanian', value: 'ro' },
    { label: 'Russian', value: 'ru' },
    { label: 'Swedish', value: 'sv' },
    { label: 'TURKISH', value: 'tr' },
    { label: 'Vietnamese', value: 'vi' },
    { label: 'Simplified Chinese', value: 'zh_CN' },
    { label: 'Traditional Chinese', value: 'zh_TW' }
  ],
  PACING: [
    { label: 'Accelerated', value: 'accelerated' },
    { label: 'Standard', value: 'standard' }
  ],
  DEFAULT_PACING: 'accelerated',
  KEY_URL_TRACKING: 'urlTracking',
  ACCOUNT_NAME: 'Shinez',
  DEFAULT_TARGET_CPA: 1,
  DEFAULT_OPTIMIZATION_TYPE: '-1'
};

export const OB = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  ALL: 'ALL',
  IOS: 'Ios',
  ANDROID: 'Android',
  MACOS: 'MacOs',
  LINUX: 'Linux',
  WINDOWS: 'Windows',
  OTHER: 'Other',
  ACTIVE: 'active',
  INACTIVE: 'paused',
  STANDARD: 'Standard',
  AUTO: 'Auto',
  SAFARI: 'Safari',
  OPERA: 'Opera',
  CHROME: 'Chrome',
  UC_BROWSER: 'UCBrowser',
  INAPP: 'InApp',
  SAMSUNG: 'Samsung',
  FIREFOX: 'Firefox',
  INTERNET_EXPLORER: 'InternetExplorer',
  MIN_BUDGET: 20,
  DEFAULT_BUDGET: 30,
  DEFAULT_BID: 0.03,
  DEFAULT_SITE: ['spb'],
  MIN_BID: 0.03,
  MAX_BID: 0.2,
  MIN_CONVERSION_BID: 0.001,
  MAX_CONVERSION_BID: 0.5,
  MAX_WARN_BID: 0.2,
  KEY_ARTICLE: 'article',
  KEY_AUDIENCE: 'audience',
  KEY_DEVICE: 'device',
  KEY_OS: 'os',
  KEY_BID_STRATEGY: 'bid_strategy',
  KEY_BID_STRATEGY_UI: 'bid_strategy_ui',
  KEY_BID_TYPE: 'bid_type',
  KEY_BUDGET: 'budget',
  KEY_BUDGET_AMOUNT: 'budget_amount',
  KEY_CAMPAIGN_TYPE: 'campaign_type',
  KEY_COUNTRY: 'country',
  KEY_CREATIVE_GROUPS: 'creativeGroups',
  KEY_CREATOR: 'campaignCreator',
  KEY_DEFAULT_BID: 'defaultBid',
  KEY_BID_CAP: 'automaticModeMaxCpc',
  KEY_MAX_CPC_LIMIT: 'maxCpcBidPercentage',
  KEY_MARKETING_OBJECTIVE: 'marketing_objective',
  KEY_SPENDING_LIMIT_MODEL: 'spending_limit_model',
  KEY_ORIGINAL_SOURCE_DATA: 'original_source_data',
  KEY_SITE: 'site',
  KEY_STATUS: 'status',
  KEY_BIDS: 'bids',
  KEY_PREFIX: 'prefix',
  KEY_QUERY_PARAMETERS: 'qs',
  KEY_CONVERSION: 'conversion',
  KEY_SOFT_CONVERSIONS: 'softConversions',
  KEY_CONVERSION_RATE: 'conversion_rate',
  KEY_CAMPAIGN_START_TIME: 'campaign_start_time',
  KEY_CAMPAIGN_START_TIME_RANGE: 'campaign_start_time_range',
  KEY_CAMPAIGN_START_DATES: 'campaign_start_dates',
  KEY_CAMPAIGN_STOP_TIME: 'campaign_stop_time',
  KEY_BROWSER: 'browser',
  KEY_INTERESTS: 'interests',
  KEY_OS_CHOICES: 'osChoices',
  MIN_WIDTH: 600,
  MIN_HEIGHT: 400,
  MAX_TITLE_CHARS: 100,
  MAX_DESCRIPTION_CHARS: 150,
  STATUS: [
    { label: 'Active', value: 'active' },
    { label: 'Paused', value: 'paused' }
  ],
  DEVICES: [
    { label: 'Desktop', value: 'DESKTOP' },
    { label: 'Mobile', value: 'MOBILE' },
    { label: 'Tablet', value: 'TABLET' }
  ],
  ALLOWED_VERSIONS: ['shz', 'apo', 'oct', 'bra', 'wzm', 'obr', 'jsn'],
  SITE_ALLOWED_VERSIONS: {
    tup: ['shz', 'apo', 'oct', 'mim'],
    nmi: ['shz', 'apo', 'oct', 'mim'],
    fin: ['shz', 'oct'],
    tps: ['shz']
  },
  EXCLUDE_SITES: ['rfg', 'cas'],
  DEFAULT_VERSION: ['shz'],
  BID_STEP: 0.01,
  BID_CONVERSION_STEP: 0.001,
  KEY_DEVICE_DUPLICATION_TYPE: 'deviceDuplicationType',
  KEY_SEMI_OPTIMIZATION: 'semiOptimization',
  KEY_OS_DUPLICATION_TYPE: 'osDuplicationType',
  NMI_ACCOUNT_ID: '0008f0c2be727cea253ab203ad22479724',
  SEARCH_EN_ACCOUNT_ID: '00c3685e1eaf90484b62591e77288dc81d',
  MAX_CAMP_NAME_LENGTH: 40,
  MAX_BRANDING_TEXT_LENGTH: 60,
  ACTIVE_DOMAINS: ['spb', 'mdm', 'trm', 'yds'],
  CONVERSIONS_LIST: [
    { label: 'EPC 0.01', value: 'EPC_0.01' },
    { label: 'EPC 0.02', value: 'EPC_0.02' },
    { label: 'EPC 0.03', value: 'EPC_0.03' },
    { label: 'EPC 0.04', value: 'EPC_0.04' },
    { label: 'EPC 0.05', value: 'EPC_0.05' },
    { label: 'EPC 0.06', value: 'EPC_0.06' },
    { label: 'EPC 0.07', value: 'EPC_0.07' },
    { label: 'EPC 0.08', value: 'EPC_0.08' },
    { label: 'EPC 0.09', value: 'EPC_0.09' },
    { label: 'EPC 0.10', value: 'EPC_0.10' },
    { label: 'EPC 0.11', value: 'EPC_0.11' },
    { label: 'EPC 0.12', value: 'EPC_0.12' },
    { label: 'EPC 0.13', value: 'EPC_0.13' },
    { label: 'EPC 0.14', value: 'EPC_0.14' },
    { label: 'EPC 0.15', value: 'EPC_0.15' },
    { label: 'EPC 0.16', value: 'EPC_0.16' },
    { label: 'EPC 0.17', value: 'EPC_0.17' },
    { label: 'EPC 0.18', value: 'EPC_0.18' },
    { label: 'EPC 0.19', value: 'EPC_0.19' },
    { label: 'EPC 0.20', value: 'EPC_0.20' },
    { label: 'EPC 0.21', value: 'EPC_0.21' },
    { label: 'EPC 0.22', value: 'EPC_0.22' },
    { label: 'EPC 0.23', value: 'EPC_0.23' },
    { label: 'EPC 0.24', value: 'EPC_0.24' },
    { label: 'EPC 0.25', value: 'EPC_0.25' },
    { label: 'EPC 0.26', value: 'EPC_0.26' },
    { label: 'EPC 0.27', value: 'EPC_0.27' },
    { label: 'EPC 0.28', value: 'EPC_0.28' },
    { label: 'EPC 0.29', value: 'EPC_0.29' },
    { label: 'EPC 0.30', value: 'EPC_0.30' }
  ],
  SPENDING_MODEL_LIST: [
    {
      label: 'Campaign',
      value: 'CAMPAIGN'
    },
    {
      label: 'Monthly',
      value: 'MONTHLY'
    },
    {
      label: 'Daily',
      value: 'DAILY'
    }
  ],
  BID_TYPE_LIST: [
    {
      label: 'Spend ASAP',
      value: 'SPEND_ASAP'
    },
    {
      label: 'Automatic',
      value: 'AUTOMATIC'
    },
    {
      label: 'Daily target',
      value: 'DAILY_TARGET'
    }
  ],
  BID_STRATEGY_LIST: [
    {
      label: 'Conversions',
      value: 'CPC'
    },
    {
      label: 'Max Conversion',
      value: 'MAX_CONVERSION_FULLY_AUTOMATED'
    }
  ],
  DEFAULT_BID_STRATEGY: [
    {
      label: 'Max Conversion',
      value: 'MAX_CONVERSION_FULLY_AUTOMATED'
    }
  ],
  BROWSERS: [
    {
      label: 'Safari',
      value: 'Safari'
    },
    {
      label: 'Opera',
      value: 'Opera'
    },
    {
      label: 'Chrome',
      value: 'Chrome'
    },
    {
      label: 'UCBrowser',
      value: 'UCBrowser'
    },
    {
      label: 'InApp',
      value: 'InApp'
    },
    {
      label: 'Samsung',
      value: 'Samsung'
    },
    {
      label: 'Firefox',
      value: 'Firefox'
    },
    {
      label: 'InternetExplorer',
      value: 'InternetExplorer'
    }
  ],
  OS: {
    MOBILE: ['Ios', 'Android'],
    TABLET: ['Ios', 'Android'],
    DESKTOP: ['Windows', 'MacOs'],
    ALL: []
  },
  DESKTOP_ASPECT_RATIO: 1.5,
  DESKTOP_ASPECT_RATIO_INFO: '3:2',
  SMARTPHONE_ASPECT_RATIO: 1
};
export const ZM = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  ALL: 'ALL',
  IOS: 'iOS',
  ANDROID: 'Android',
  MACOS: 'macOS',
  LINUX: 'Linux',
  WINDOWS: 'Windows',
  WINDOWS_PHONE: 'Windows Phone',
  OTHER: 'Other',
  ACTIVE: 'active',
  INACTIVE: 'paused',
  STANDARD: 'Standard',
  AUTO: 'Auto',
  SAFARI: 'Safari',
  OPERA: 'Opera',
  CHROME_OS: 'ChromeOS',
  WINRT: 'WinRT',
  UC_BROWSER: 'UCBrowser',
  INAPP: 'InApp',
  SAMSUNG: 'Samsung',
  FIREFOX: 'Firefox',
  INTERNET_EXPLORER: 'InternetExplorer',
  MIN_BUDGET: 20,
  DEFAULT_BUDGET: 30,
  DEFAULT_BID: 0.03,
  DEFAULT_SITE: ['spb'],
  MIN_BID: 0.03,
  MAX_BID: 0.2,
  MIN_CONVERSION_BID: 0.001,
  MAX_CONVERSION_BID: 0.5,
  MAX_WARN_BID: 0.2,
  KEY_ARTICLE: 'article',
  KEY_AUDIENCE: 'audience',
  KEY_DEVICE: 'device',
  KEY_OS: 'os',
  KEY_BID_STRATEGY: 'bid_strategy',
  KEY_BID_STRATEGY_UI: 'bid_strategy_ui',
  KEY_BID_TYPE: 'bid_type',
  KEY_BUDGET: 'budget',
  KEY_BUDGET_AMOUNT: 'budget_amount',
  KEY_CAMPAIGN_TYPE: 'campaign_type',
  KEY_COUNTRY: 'country',
  KEY_CREATIVE_GROUPS: 'creativeGroups',
  KEY_CREATOR: 'campaignCreator',
  KEY_DEFAULT_BID: 'defaultBid',
  KEY_BID_CAP: 'automaticModeMaxCpc',
  KEY_MAX_CPC_LIMIT: 'maxCpcBidPercentage',
  KEY_MARKETING_OBJECTIVE: 'marketing_objective',
  KEY_SPENDING_LIMIT_MODEL: 'spending_limit_model',
  KEY_ORIGINAL_SOURCE_DATA: 'original_source_data',
  KEY_SITE: 'site',
  KEY_STATUS: 'status',
  KEY_BIDS: 'bids',
  KEY_PREFIX: 'prefix',
  KEY_QUERY_PARAMETERS: 'qs',
  KEY_CONVERSION: 'conversion',
  KEY_CONVERSION_RATE: 'conversion_rate',
  KEY_CAMPAIGN_START_TIME: 'campaign_start_time',
  KEY_CAMPAIGN_START_TIME_RANGE: 'campaign_start_time_range',
  KEY_CAMPAIGN_START_DATES: 'campaign_start_dates',
  KEY_CAMPAIGN_STOP_TIME: 'campaign_stop_time',
  KEY_BROWSER: 'browser',
  KEY_OS_CHOICES: 'osChoices',
  KEY_PUBLISHER: 'publishers',
  KEY_SOURCE: 'source',
  KEY_ENVIRONMENT: 'environment',
  KEY_LANGUAGE: 'language',
  MIN_WIDTH: 600,
  MIN_HEIGHT: 400,
  MAX_TITLE_CHARS: 100,
  MAX_DESCRIPTION_CHARS: 150,
  PUBLISHERS: [
    { label: 'Performance_Publishers_Top_Pubs_Aug24', value: '52781640' },
    { label: 'Placements_BL_Aug24', value: '52781643' },
    { label: 'Publisher_BL_Aug24', value: '52781644' },
    { label: 'In-App From Competitors All Sources', value: '53968015' },
    { label: 'In-App News All Sources', value: '53968017' },
    { label: 'Top Placements Apps Feb25', value: '54078698' }
  ],
  STATUS: [
    { label: 'Active', value: 'active' },
    { label: 'Paused', value: 'paused' }
  ],
  DEVICES: [
    { label: 'Desktop', value: 'DESKTOP' },
    { label: 'Mobile', value: 'MOBILE' },
    { label: 'Tablet', value: 'TABLET' }
  ],
  ALLOWED_VERSIONS: ['shz', 'apo', 'oct', 'bra', 'wzm', 'obr', 'jsn'],
  SITE_ALLOWED_VERSIONS: {
    tup: ['shz', 'apo', 'oct', 'mim'],
    nmi: ['shz', 'apo', 'oct', 'mim'],
    fin: ['shz', 'oct'],
    tps: ['shz']
  },
  EXCLUDE_SITES: ['rfg', 'cas'],
  DEFAULT_VERSION: ['shz'],
  BID_STEP: 0.01,
  BID_CONVERSION_STEP: 0.001,
  KEY_DEVICE_DUPLICATION_TYPE: 'deviceDuplicationType',
  KEY_OS_DUPLICATION_TYPE: 'osDuplicationType',
  MAX_CAMP_NAME_LENGTH: 40,
  MAX_BRANDING_TEXT_LENGTH: 60,
  ACTIVE_DOMAINS: ['spb', 'mdm', 'trm', 'yds'],
  CONVERSIONS_LIST: [
    { label: 'EPC 0.01', value: 'EPC_0.01' },
    { label: 'EPC 0.02', value: 'EPC_0.02' },
    { label: 'EPC 0.03', value: 'EPC_0.03' },
    { label: 'EPC 0.04', value: 'EPC_0.04' },
    { label: 'EPC 0.05', value: 'EPC_0.05' },
    { label: 'EPC 0.06', value: 'EPC_0.06' },
    { label: 'EPC 0.07', value: 'EPC_0.07' },
    { label: 'EPC 0.08', value: 'EPC_0.08' },
    { label: 'EPC 0.09', value: 'EPC_0.09' },
    { label: 'EPC 0.10', value: 'EPC_0.10' },
    { label: 'EPC 0.11', value: 'EPC_0.11' },
    { label: 'EPC 0.12', value: 'EPC_0.12' },
    { label: 'EPC 0.13', value: 'EPC_0.13' },
    { label: 'EPC 0.14', value: 'EPC_0.14' },
    { label: 'EPC 0.15', value: 'EPC_0.15' },
    { label: 'EPC 0.16', value: 'EPC_0.16' },
    { label: 'EPC 0.17', value: 'EPC_0.17' },
    { label: 'EPC 0.18', value: 'EPC_0.18' },
    { label: 'EPC 0.19', value: 'EPC_0.19' },
    { label: 'EPC 0.20', value: 'EPC_0.20' },
    { label: 'EPC 0.21', value: 'EPC_0.21' },
    { label: 'EPC 0.22', value: 'EPC_0.22' },
    { label: 'EPC 0.23', value: 'EPC_0.23' },
    { label: 'EPC 0.24', value: 'EPC_0.24' },
    { label: 'EPC 0.25', value: 'EPC_0.25' },
    { label: 'EPC 0.26', value: 'EPC_0.26' },
    { label: 'EPC 0.27', value: 'EPC_0.27' },
    { label: 'EPC 0.28', value: 'EPC_0.28' },
    { label: 'EPC 0.29', value: 'EPC_0.29' },
    { label: 'EPC 0.30', value: 'EPC_0.30' }
  ],
  SPENDING_MODEL_LIST: [
    {
      label: 'Standard',
      value: 'STANDARD'
    },
    {
      label: 'Accelerated',
      value: 'ACCELERATED'
    }
  ],
  BID_TYPE_LIST: [
    {
      label: 'Max CPC',
      value: 'MAXCPC'
    },
    {
      label: 'Max CPA',
      value: 'MAXCPA'
    }
  ],
  BID_STRATEGY_LIST: [
    {
      label: 'ACTIVE',
      value: 'ACTIVE'
    },
    {
      label: 'INACTIVE',
      value: 'INACTIVE'
    }
  ],
  ENVIRONMENT_LIST: [
    {
      label: 'In apps',
      value: 'APP'
    },
    {
      label: 'On websites',
      value: 'SITE'
    }
  ],
  BROWSERS: [
    {
      label: 'Safari',
      value: 'Safari'
    },
    {
      label: 'Opera',
      value: 'Opera'
    },
    {
      label: 'Chrome',
      value: 'Chrome'
    },
    {
      label: 'UCBrowser',
      value: 'UCBrowser'
    },
    {
      label: 'InApp',
      value: 'InApp'
    },
    {
      label: 'Samsung',
      value: 'Samsung'
    },
    {
      label: 'Firefox',
      value: 'Firefox'
    },
    {
      label: 'InternetExplorer',
      value: 'InternetExplorer'
    }
  ],
  OS: {
    MOBILE: ['iOS', 'Android', 'Windows Phone', 'ChromeOS'],
    TABLET: ['iOS', 'Android', 'Windows Phone', 'ChromeOS'],
    DESKTOP: ['Windows', 'MacOS', 'Linux', 'ChromeOS'],
    ALL: ['iOS', 'Android', 'Windows Phone', 'ChromeOS', 'Windows', 'MacOS', 'Linux']
  },
  DESKTOP_ASPECT_RATIO: 1.5,
  DESKTOP_ASPECT_RATIO_INFO: '3:2',
  SMARTPHONE_ASPECT_RATIO: 1
};
export const PN = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  ALL: 'ALL',
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  MACOS: 'MAC',
  LINUX: 'Linux',
  WINDOWS: 'WINDOWS',
  OTHER: 'Other',
  ACTIVE: 'active',
  INACTIVE: 'paused',
  STANDARD: 'Standard',
  AUTO: 'Auto',
  SAFARI: 'Safari',
  OPERA: 'Opera',
  CHROME: 'Chrome',
  UC_BROWSER: 'UCBrowser',
  INAPP: 'InApp',
  SAMSUNG: 'Samsung',
  FIREFOX: 'Firefox',
  INTERNET_EXPLORER: 'InternetExplorer',
  MIN_BUDGET: 1,
  DEFAULT_BUDGET: 10,
  DEFAULT_BID: 0.03,
  DEFAULT_SITE: ['plj'],
  MIN_BID: 0.25,
  MAX_BID: 0.9,
  MAX_PIXEL: 0.3,
  MAX_WARN_BID: 0.2,
  KEY_ARTICLE: 'article',
  KEY_DAILY_CAP: 'daily_cap',
  KEY_LIFETIME_CAP: 'lifetime_cap',
  KEY_AUDIENCE: 'audience',
  KEY_DEVICE: 'device',
  KEY_OS: 'os',
  KEY_BID_STRATEGY: 'bid_strategy',
  KEY_OBJECTIVE: 'objective',
  KEY_BOARD: 'board',
  KEY_TARGETING_STRATEGY: 'targeting_strategy',
  KEY_BID_TYPE: 'bid_type',
  KEY_BUDGET: 'budget',
  KEY_BUDGET_AMOUNT: 'budget_amount',
  KEY_CAMPAIGN_TYPE: 'campaign_type',
  KEY_COUNTRY: 'country',
  KEY_CREATIVE_GROUPS: 'creativeGroups',
  KEY_CREATOR: 'campaignCreator',
  KEY_DEFAULT_BID: 'defaultBid',
  KEY_PIXEL: 'pixel',
  KEY_BID_CAP: 'automaticModeMaxCpc',
  KEY_ORIGINAL_SOURCE_DATA: 'original_source_data',
  KEY_SITE: 'site',
  KEY_STATUS: 'status',
  KEY_BIDS: 'bids',
  KEY_PREFIX: 'prefix',
  KEY_QUERY_PARAMETERS: 'qs',
  KEY_CONVERSION: 'conversion',
  KEY_CAMPAIGN_START_TIME: 'campaign_start_time',
  KEY_CAMPAIGN_START_TIME_RANGE: 'campaign_start_time_range',
  KEY_CAMPAIGN_STOP_TIME_RANGE: 'campaign_stop_time_range',
  KEY_CAMPAIGN_START_DATES: 'campaign_start_dates',
  KEY_CAMPAIGN_STOP_DATES: 'campaign_stop_dates',
  KEY_CAMPAIGN_STOP_TIME: 'campaign_stop_time',
  KEY_OS_CHOICES: 'osChoices',
  KEY_BUDGET_TYPE: 'budget_type',
  MIN_WIDTH: 600,
  MIN_HEIGHT: 400,
  MAX_TITLE_CHARS: 100,
  MAX_DESCRIPTION_CHARS: 800,
  BUDGET_TYPE: [
    { label: 'Daily', value: 'DAILY' },
    { label: 'Lifetime', value: 'LIFETIME' },
    { label: 'CBO Ad group', value: 'CBO_ADGROUP' }
  ],
  STATUS: [
    { label: 'Active', value: 'active' },
    { label: 'Paused', value: 'paused' }
  ],
  DEVICES: [
    { label: 'Desktop', value: 'DESKTOP' },
    { label: 'Mobile', value: 'MOBILE' }
  ],
  PIXEL_LIST: [
    { label: '0.1', value: '0.1' },
    { label: '0.2', value: '0.2' },
    { label: '0.3', value: '0.3' }
  ],
  ALLOWED_VERSIONS: ['shz', 'apo', 'oct', 'bra', 'wzm', 'obr', 'jsn'],
  SITE_ALLOWED_VERSIONS: {
    tup: ['shz', 'apo', 'oct', 'mim'],
    nmi: ['shz', 'apo', 'oct', 'mim'],
    fin: ['shz', 'oct'],
    tps: ['shz']
  },
  EXCLUDE_SITES: ['rfg', 'cas'],
  DEFAULT_VERSION: ['shz'],
  OBJECTIVE: [
    // { label: 'AWARENESS', value: 'AWARENESS' },
    // { label: 'CONSIDERATION', value: 'CONSIDERATION' },
    { label: 'WEB_CONVERSION', value: 'WEB_CONVERSION' }
    // { label: 'CATALOG_SALES', value: 'CATALOG_SALES' },
    // { label: 'VIDEO_COMPLETION', value: 'VIDEO_COMPLETION' }
  ],
  TARGETING_STRATEGY: [
    { label: 'With key words', value: 'with_key' },
    { label: 'Without key words', value: 'without_key' }
  ],
  BID_STEP: 0.01,
  PIXEL_STEP: 0.01,
  BID_CONVERSION_STEP: 0.001,
  KEY_DEVICE_DUPLICATION_TYPE: 'deviceDuplicationType',
  KEY_DESTINATION_ACCOUNT: 'destinationAccount',
  KEY_ACCOUNT_CODE: 'accountCode',
  KEY_OS_DUPLICATION_TYPE: 'osDuplicationType',
  NMI_ACCOUNT_ID: '0008f0c2be727cea253ab203ad22479724',
  SEARCH_EN_ACCOUNT_ID: '00c3685e1eaf90484b62591e77288dc81d',
  MAX_CAMP_NAME_LENGTH: 40,
  MAX_BRANDING_TEXT_LENGTH: 60,
  ACTIVE_DOMAINS: ['spb', 'mdm', 'trm', 'yds'],
  BID_TYPE_LIST: [
    {
      label: 'Spend ASAP',
      value: 'SPEND_ASAP'
    },
    {
      label: 'Automatic',
      value: 'AUTOMATIC'
    },
    {
      label: 'Daily target',
      value: 'DAILY_TARGET'
    }
  ],
  BID_STRATEGY_TYPE: [
    {
      label: 'Automatic bid',
      value: 'AUTOMATIC_BID'
    },
    {
      label: 'Max bid',
      value: 'MAX_BID'
    },
    {
      label: 'Target avg',
      value: 'TARGET_AVG'
    }
  ],
  BROWSERS: [
    {
      label: 'Safari',
      value: 'Safari'
    },
    {
      label: 'Opera',
      value: 'Opera'
    },
    {
      label: 'Chrome',
      value: 'Chrome'
    },
    {
      label: 'UCBrowser',
      value: 'UCBrowser'
    },
    {
      label: 'InApp',
      value: 'InApp'
    },
    {
      label: 'Samsung',
      value: 'Samsung'
    },
    {
      label: 'Firefox',
      value: 'Firefox'
    },
    {
      label: 'InternetExplorer',
      value: 'InternetExplorer'
    }
  ],
  OS: {
    MOBILE: ['Ios', 'Android'],
    TABLET: ['Ios', 'Android'],
    DESKTOP: ['Windows', 'MacOs'],
    ALL: []
  },
  DESKTOP_ASPECT_RATIO: 1.5,
  DESKTOP_ASPECT_RATIO_INFO: '3:2',
  SMARTPHONE_ASPECT_RATIO: 1
};

export const TW = {
  MIN_BUDGET_LIMIT: 0,
  MAX_BUDGET_LIMIT: 500,
  MAX_IMAGE_FILESIZE: 2097152,
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
  SMARTPHONE: 'SMARTPHONE',
  IOS: 'iOS',
  ANDROID: 'Android',
  MACOS: 'Mac OS X',
  LINUX: 'Linux',
  WINDOWS: 'Windows',
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  STATUS: [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'PAUSED', value: 'PAUSED' }
  ],
  DEFAULT_BUDGET: 0,
  DEFAULT_BID: 0.05,
  DEFAULT_SITE: ['trm'],
  DEFAULT_FOUNDING_SOURCE: 'Shinez - EST Credit Line',
  DEFAULT_AGE: 'AGE_OVER_18',
  DEFAULT_COUNTRY: 'us',
  MIN_BID: 0.01,
  MAX_BID: 1,
  MAX_WARN_BID: 0.2,
  KEY_AGE: 'age',
  KEY_ARTICLE: 'article',
  KEY_ACCOUNT_NAME: 'accountName',
  KEY_DEVICE: 'device',
  KEY_OS: 'os',
  KEY_BID: 'bid',
  KEY_BID_TYPE: 'bid_type',
  KEY_BRANDING_TEXT: 'branding_text',
  KEY_BUDGET: 'budget',
  KEY_BUDGET_TYPE: 'budgetType',
  KEY_BIDDING_STRATEGY: 'biddingStrategy',
  KEY_COUNTRY: 'country',
  KEY_CREATIVE_GROUPS: 'creativeGroups',
  KEY_CREATOR: 'campaignCreator',
  KEY_CONVERSION: 'conversion',
  KEY_DEFAULT_BID: 'defaultBid',
  KEY_FOUNDING_SOURCE: 'foundingSource',
  KEY_FULL_SITE: 'fullSite',
  KEY_GENDER: 'gender',
  KEY_GEO_NAME: 'geoName',
  KEY_MARKETING_OBJECTIVE: 'marketing_objective',
  KEY_OBJECTIVE: 'objective',
  KEY_GOAL: 'goal',
  KEY_SPENDING_LIMIT_MODEL: 'spending_limit_model',
  KEY_SITE: 'site',
  KEY_STATUS: 'status',
  KEY_BIDS: 'bids',
  KEY_PREFIX: 'prefix',
  KEY_QUERY_PARAMETERS: 'qs',
  KEY_CAMPAIGN_START_TIME: 'startDateStr',
  KEY_CAMPAIGN_STOP_TIME: 'endDateStr',
  KEY_DESTINATION_ACCOUNT: 'destinationAccount',
  KEY_DESTINATION_ACCOUNT_MANUAL: 'destinationAccountManual',
  KEY_LANGUAGE: 'language',
  KEY_GEO_DUPLICATION_TYPE: 'geoDuplicationType',
  KEY_DEVICE_DUPLICATION_TYPE: 'deviceDuplicationType',
  KEY_WEBSITE: 'website',
  MIN_WIDTH: 800,
  MIN_HEIGHT: 800,
  MIN_WIDTH_DESKTOP: 1520,
  SMARTPHONE_ASPECT_RATIO: 1,
  DESKTOP_ASPECT_RATIO: 1.9,
  MAX_TEXT_CHARS: 280,
  MAX_TITLE_CHARS: 70,
  ALLOWED_VERSIONS: ['shz', 'apo', 'oct', 'bra', 'wzm', 'jsn'],
  SITE_ALLOWED_VERSIONS: {
    tup: ['shz', 'apo', 'oct', 'mim'],
    nmi: ['shz', 'apo', 'oct', 'mim'],
    spn: ['spn', 'shz'],
    esc: ['esc'],
    tdw: ['shz'],
    yrb: ['shz'],
    sds: ['shz'],
    fin: ['shz', 'oct'],
    tps: ['shz']
  },
  EXCLUDE_SITES: ['cas'],
  DEFAULT_VERSION: ['shz'],
  BID_STEP: 0.01,
  MSN_SAFE: 'MSN_SAFE',
  API_DESKTOP: 'DESKTOP',
  API_MOBILE: 'SMARTPHONE ',
  API_TABLET: 'TABLET',
  MODIFIER_TASK: 'Modify bids',
  MAX_CAMP_NAME_LENGTH: 40,
  ACCOUNT_ID: '1815726',
  MAX_ADSET_PART_LENGTH: 4,
  MAX_AD_PART_LENGTH: 3,
  DEFAULT_ACCOUNT_ID: '18ce55a0x5t',
  LANGUAGE_LIST: [
    { label: 'Arabic', value: 'ar' },
    { label: 'Chinese', value: 'zh' },
    { label: 'Dutch', value: 'nl' },
    { label: 'English', value: 'en' },
    { label: 'Farsi', value: 'fa' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'German', value: 'de' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Korean', value: 'ko' },
    { label: 'Norwegian', value: 'no' },
    { label: 'Polish', value: 'pl' },
    { label: 'Portuguese', value: 'pt' },
    { label: 'Russian', value: 'ru' },
    { label: 'Spanish', value: 'es' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Thai', value: 'th' },
    { label: 'Turkish', value: 'tr' }
  ],
  DEVICES_LIST: [
    { label: 'iOS', value: '0' },
    { label: 'Android', value: '1' },
    { label: 'Mobile web on other devices', value: '3' },
    { label: 'Desktop and laptop computers', value: '4' }
  ],
  AGE_LIST: [
    { label: '13 to 24', value: 'AGE_13_TO_24' },
    { label: '13 to 34', value: 'AGE_13_TO_34' },
    { label: '13 to 49', value: 'AGE_13_TO_49' },
    { label: '13 to 54', value: 'AGE_13_TO_54' },
    { label: '13 and up', value: 'AGE_OVER_13' },
    { label: '18 to 24', value: 'AGE_18_TO_24' },
    { label: '18 to 34', value: 'AGE_18_TO_34' },
    { label: '18 to 49', value: 'AGE_18_TO_49' },
    { label: '18 to 54', value: 'AGE_18_TO_54' },
    { label: '18 and up', value: 'AGE_OVER_18' },
    { label: '21 to 34', value: 'AGE_21_TO_34' },
    { label: '21 to 49', value: 'AGE_21_TO_49' },
    { label: '21 to 54', value: 'AGE_21_TO_54' },
    { label: '21 and up', value: 'AGE_OVER_21' },
    { label: '25 to 49', value: 'AGE_25_TO_49' },
    { label: '25 to 54', value: 'AGE_25_TO_54' },
    { label: '25 and up', value: 'AGE_OVER_25' },
    { label: '35 to 49', value: 'AGE_35_TO_49' },
    { label: '35 to 54', value: 'AGE_35_TO_54' },
    { label: '35 and up', value: 'AGE_OVER_35' },
    { label: '50 and up', value: 'AGE_OVER_50' }
  ],
  GOAL_LIST: [
    { label: 'Site visits', value: 'SITE_VISITS' },
    { label: 'Link clicks', value: 'DEFAULT' },
    { label: 'Website conversions', value: 'WEBSITE_CONVERSIONS' }
  ],
  GENDER_LIST: [
    { label: 'Men', value: 'MALE' },
    { label: 'Women', value: 'FEMALE' }
  ],
  PROD_SERVER: 'wss://twitter-server.shinez.io',
  STAGING_SERVER: 'wss://twitter-server.staging.shinez.io',
  IOS_DEVICE: '0',
  ANDROID_DEVICE: '1',
  DESKTOP_DEVICE: '4',
  DOMAIN_TO_ACCOUNT: {
    yds: '18ce553xi99',
    spb: '18ce550mnsz',
    trm: '18ce550i79d',
    mdm: '18ce550ni8l',
    mob: '18ce550mnsl',
    hpt: '18ce553okgm',
    fih: '18ce553ps2z',
    tpd: '18ce553xi9q',
    fdw: '18ce55380xv',
    plj: '18ce550i7dt',
    bts: '18ce552vfz1',
    trd: '18ce551etza',
    fnc: '18ce551eu0d',
    ofl: '18ce553tw6z',
    edk: '18ce550i7fg',
    yss: '18ce553okhr',
    omg: '18ce5529m7f',
    edm: '18ce551etzq',
    ttb: '18ce551etxw',
    efu: '18ce553owly',
    tdc: '18ce553xiab',
    tdd: '18ce553xi8o',
    wtn: '18ce553owmd',
    sol: '18ce553psuw',
    yrb: '18ce550ni8a',
    sds: '18ce550ni8a',
    ydp: '18ce55a0x51',
    sth: '18ce55a0x5t',
    sps: '18ce55a0x69'
  },
  BULK_CONVERSIONS: [
    {
      label: 'Site Visit all sites',
      value: 'site visit all sites'
    },
    {
      label: 'Site visits',
      value: 'site visits'
    },
    {
      label: 'Landing page views',
      value: 'landing page views'
    }
  ],
  OBJECTIVES: [
    { label: 'Standard', value: 'standard' },
    { label: 'Keyword', value: 'keywords' }
  ]
};

export const FB = {
  CUSTOM_USERS: ['erez@gaga-net.com', 'test-user1@testtest.com', 'test-gaga@test.com'],
  NITIDO_BM_ID: '2290193611261596',
  QUIZ: 'quiz',
  // call to check locale (for APP_LANGUAGE_LIST)
  // curl -G -d 'type=adlocale' -d 'q=ja' -d 'access_token=' https://graph.facebook.com/v19.0/search
  APP_LANGUAGE_LIST:
    '[{"value": 1001, "label": "English"},{"value": 5, "label": "German"},{"value": 23, "label": "Spanish"} ,{"value": 1003, "label": "French"},{"value": 1005, "label": "Portuguese"},{"value": 14, "label": "Dutch"},{"value": 10, "label": "Italian"},{"value": 11, "label": "Japanese"},{ "label": "English (US)", "value": 6 },{"label":"English (UK)", "value": 24 },{"label":"Arabic", "value": 28 },{"label":"Polish", "value": 15},{"label":"Russian", "value": 17},{"label":"Hindi", "value": 46},{"label":"Romanian", "value": 32}]',
  APP_LANGUAGE_OPTIONS: [
    { value: 1001, label: 'English' },
    { value: 5, label: 'German' },
    { value: 23, label: 'Spanish' },
    { value: 1003, label: 'French' },
    { value: 1005, label: 'Portuguese' },
    { value: 14, label: 'Dutch' },
    { value: 10, label: 'Italian' },
    { value: 11, label: 'Japanese' }
  ],
  DYNAMIC_CC_USERS: ['nof@shinez.io', 'shinezarb@clicksco.com'], // I swear it is temporary 18.10.2023
  KEY_ADVERT_TITLE: 'advertTitle',
  ARTICLE_BLOCK_B2B: {
    'disabled-b2b-all-all': 1,
    'disabled-b2b-fb-all': 1,
    'disabled-global-all-all': 1,
    'disabled-global-fb-all': 1
  },
  ARTICLE_BLOCK_SWAG: {
    'disabled-swag-all-all': 1,
    'disabled-swag-fb-all': 1,
    'disabled-global-all-all': 1,
    'disabled-global-fb-all': 1
  },
  ALLOWED_VERSIONS: ['shz', 'oct', 'apo', 'bra', 'tst', 'jsn', 'frs', 'wzm'],
  B2B_ALLOWED_VERSIONS: ['shz', 'bra', 'wzm', 'frs', 'apo'],
  SITE_ALLOWED_VERSIONS: {
    tup: ['shz', 'oct', 'apo', 'mim'],
    nmi: ['shz', 'oct', 'apo', 'mim'],
    fin: ['shz', 'oct'],
    tps: ['shz'],
    yds: ['shz', 'oct', 'apo', 'bra', 'tst', 'frs', 'wzm'],
    tdc: ['shz', 'oct', 'apo', 'bra', 'tst', 'frs', 'wzm'],
    trd: ['shz', 'oct', 'apo', 'bra', 'tst', 'frs', 'wzm'],
    fih: ['shz', 'oct', 'apo', 'bra', 'tst', 'frs', 'wzm']
  },
  KEY_SITE: 'site',
  LANGUAGES: {
    1001: 'en',
    5: 'de',
    23: 'es',
    1003: 'fr',
    1005: 'pt',
    14: 'nl',
    28: 'ar',
    17: 'ru',
    10: 'it',
    15: 'pl',
    50: 'ml',
    32: 'ro',
    39: 'el',
    18: 'sv'
  },
  LANGUAGE_LIST: [
    { label: 'English (US)', value: 6 },
    { label: 'English (UK)', value: 24 },
    { label: 'English', value: 1001 },
    { label: 'German', value: 5 },
    { label: 'Spanish', value: 23 },
    { label: 'Italian', value: 10 },
    { label: 'Japanese', value: 11 },
    { label: 'Korean', value: 12 },
    { label: 'Dutch', value: 14 },
    { label: 'Swedish', value: 18 },
    { label: 'Turkish', value: 19 },
    { label: 'French', value: 1003 },
    { label: 'Chinese', value: 1004 },
    { label: 'Portuguese', value: 1005 }
  ],
  BUDGET_TYPE: [
    { label: 'daily campaign', value: 'daily campaign' },
    { label: 'lifetime campaign', value: 'lifetime campaign' },
    { label: 'daily adset', value: 'daily adset' },
    { label: 'lifetime adset', value: 'lifetime adset' }
  ],
  MIN_WIDTH: 600,
  MIN_HEIGHT: 600,
  MAX_IMAGE_FILESIZE: 240000000,
  BUDGET_LIMIT: 1000,
  DEFAULT_MOB_CC_BID: 0.06,
  DEFAULT_DESK_CC_BID: 0.09,
  DEFAULT_ALL_CC_BID: 0.08,
  MAX_CC_BID: 2,
  FACEBOOK: 'facebook',
  MAX_CAMP_NAME_LENGTH: 40,
  MAX_ADSET_PART_LENGTH: 4,
  MAX_AD_PART_LENGTH: 3,
  MAX_TITLE_LENGTH: 255,
  MAX_TITLE_MOBILE_LENGTH: 48,
  MAX_TEXT_LENGTH: 500,
  DESKTOP_ASPECT_RATIO: 1.91,
  PLATFORM_LIST: ['desktop', 'mobile'],
  MOBILE_PLATFORM_LIST: ['Android', 'iOS'],
  DEVICE_LIST: ['Mobile', 'Desktop', 'IOS', 'Android', 'All'],
  BID_STRATEGY_TYPES: [
    { label: 'Lowest cost', value: 'LOWEST_COST_WITHOUT_CAP' },
    { label: 'Bid cap', value: 'LOWEST_COST_WITH_BID_CAP' },
    { label: 'Cost cap', value: 'COST_CAP' },
    { label: 'Lowest min ROAS', value: 'LOWEST_COST_WITH_MIN_ROAS' }
  ],
  STATUSES: [
    { label: 'On review', value: 'ON_REVIEW' },
    { label: 'Rejected', value: 'REJECTED' },
    { label: 'Approved', value: 'APPROVED' }
  ],
  GROUPS_OF_COUNTRYS_LIST: {
    us: ['us', 'ca'],
    tl: ['mx', 'co', 'es', 'ar', 'pe', 've', 'cl', 'ec', 'gt', 'bo', 'do', 'hn', 'py', 'ni', 'cr', 'pr', 'pa', 'uy'],
    da: ['de', 'at', 'ch'],
    fa: ['be', 'fr', 'ca'],
    rr: ['ru', 'by', 'lt', 'ua', 'ee', 'ge', 'md', 'az'],
    ab: ['eg', 'ae', 'sa', 'ma', 'qa', 'kw', 'jo', 'lb'],
    sc: ['fi', 'dk', 'no', 'se'],
    t1: ['us', 'ca', 'au', 'gb', 'de', 'at', 'ch'],
    e1: ['nl', 'gb', 'no', 'se', 'dk', 'de', 'ie'],
    p1: ['br', 'pt'],
    a1: ['qa', 'ae', 'sa', 'kw', 'bh', 'om'],
    wwt: ['bg', 'bl', 'ch', 'co', 'id', 'in', 'my', 'np', 'ph', 'pk', 'rs', 've'],
    big4: ['au', 'ca', 'gb', 'us']
  },
  EXCLUDE_SITES: ['rfg', 'cas'],
  SEARCH_PIXELS: ['fb7', 'fb8'],
  TEST_CAMPAIGN_NAME: 'xx-us-d-omg-100yeen-sun52-c100',
  PAUSED: 'PAUSED',
  ACTIVE: 'ACTIVE',
  AD_NAMING_LIST: [
    { label: 'Unique', value: 'Default' },
    { label: 'Same', value: 'Single' }
  ],
  ADVERT_TITLE_QUERY_PART: 'a_title={TITLE}',
  DEPC_CONVERSIONS: [
    { label: 'depc 0.01', value: 'depc 0.01' },
    { label: 'depc 0.02', value: 'depc 0.02' },
    { label: 'depc 0.03', value: 'depc 0.03' },
    { label: 'depc 0.04', value: 'depc 0.04' },
    { label: 'depc 0.05', value: 'depc 0.05' },
    { label: 'depc 0.06', value: 'depc 0.06' },
    { label: 'depc 0.07', value: 'depc 0.07' },
    { label: 'depc 0.08', value: 'depc 0.08' },
    { label: 'depc 0.09', value: 'depc 0.09' },
    { label: 'depc 0.10', value: 'depc 0.10' },
    { label: 'depc 0.11', value: 'depc 0.11' },
    { label: 'depc 0.12', value: 'depc 0.12' },
    { label: 'depc 0.13', value: 'depc 0.13' },
    { label: 'depc 0.14', value: 'depc 0.14' },
    { label: 'depc 0.15', value: 'depc 0.15' },
    { label: 'depc 0.16', value: 'depc 0.16' },
    { label: 'depc 0.17', value: 'depc 0.17' },
    { label: 'depc 0.18', value: 'depc 0.18' },
    { label: 'depc 0.19', value: 'depc 0.19' },
    { label: 'depc 0.20', value: 'depc 0.20' },
    { label: 'depc 0.21', value: 'depc 0.21' },
    { label: 'depc 0.22', value: 'depc 0.22' },
    { label: 'depc 0.23', value: 'depc 0.23' },
    { label: 'depc 0.24', value: 'depc 0.24' },
    { label: 'depc 0.25', value: 'depc 0.25' },
    { label: 'depc 0.26', value: 'depc 0.26' },
    { label: 'depc 0.27', value: 'depc 0.27' },
    { label: 'depc 0.28', value: 'depc 0.28' },
    { label: 'depc 0.29', value: 'depc 0.29' },
    { label: 'depc 0.30', value: 'depc 0.30' },
    { label: 'depc 0.31', value: 'depc 0.31' },
    { label: 'depc 0.32', value: 'depc 0.32' },
    { label: 'depc 0.33', value: 'depc 0.33' },
    { label: 'depc 0.34', value: 'depc 0.34' },
    { label: 'depc 0.35', value: 'depc 0.35' },
    { label: 'depc 0.36', value: 'depc 0.36' },
    { label: 'depc 0.37', value: 'depc 0.37' },
    { label: 'depc 0.38', value: 'depc 0.38' },
    { label: 'depc 0.39', value: 'depc 0.39' },
    { label: 'depc 0.40', value: 'depc 0.40' },
    { label: 'depc 0.41', value: 'depc 0.41' },
    { label: 'depc 0.42', value: 'depc 0.42' },
    { label: 'depc 0.43', value: 'depc 0.43' },
    { label: 'depc 0.44', value: 'depc 0.44' },
    { label: 'depc 0.45', value: 'depc 0.45' },
    { label: 'depc 0.46', value: 'depc 0.46' },
    { label: 'depc 0.47', value: 'depc 0.47' },
    { label: 'depc 0.48', value: 'depc 0.48' },
    { label: 'depc 0.49', value: 'depc 0.49' },
    { label: 'depc 0.50', value: 'depc 0.50' },
    { label: 'depc 0.51', value: 'depc 0.51' },
    { label: 'depc 0.52', value: 'depc 0.52' },
    { label: 'depc 0.53', value: 'depc 0.53' },
    { label: 'depc 0.54', value: 'depc 0.54' },
    { label: 'depc 0.55', value: 'depc 0.55' },
    { label: 'depc 0.56', value: 'depc 0.56' },
    { label: 'depc 0.57', value: 'depc 0.57' },
    { label: 'depc 0.58', value: 'depc 0.58' },
    { label: 'depc 0.59', value: 'depc 0.59' },
    { label: 'depc 0.60', value: 'depc 0.60' },
    { label: 'depc 0.61', value: 'depc 0.61' },
    { label: 'depc 0.62', value: 'depc 0.62' },
    { label: 'depc 0.63', value: 'depc 0.63' },
    { label: 'depc 0.64', value: 'depc 0.64' },
    { label: 'depc 0.65', value: 'depc 0.65' },
    { label: 'depc 0.66', value: 'depc 0.66' },
    { label: 'depc 0.67', value: 'depc 0.67' },
    { label: 'depc 0.68', value: 'depc 0.68' },
    { label: 'depc 0.69', value: 'depc 0.69' },
    { label: 'depc 0.70', value: 'depc 0.70' },
    { label: 'depc 0.71', value: 'depc 0.71' },
    { label: 'depc 0.72', value: 'depc 0.72' },
    { label: 'depc 0.73', value: 'depc 0.73' },
    { label: 'depc 0.74', value: 'depc 0.74' },
    { label: 'depc 0.75', value: 'depc 0.75' },
    { label: 'depc 0.76', value: 'depc 0.76' },
    { label: 'depc 0.77', value: 'depc 0.77' },
    { label: 'depc 0.78', value: 'depc 0.78' },
    { label: 'depc 0.79', value: 'depc 0.79' },
    { label: 'depc 0.80', value: 'depc 0.80' },
    { label: 'depc 0.81', value: 'depc 0.81' },
    { label: 'depc 0.82', value: 'depc 0.82' },
    { label: 'depc 0.83', value: 'depc 0.83' },
    { label: 'depc 0.84', value: 'depc 0.84' },
    { label: 'depc 0.85', value: 'depc 0.85' },
    { label: 'depc 0.86', value: 'depc 0.86' },
    { label: 'depc 0.87', value: 'depc 0.87' },
    { label: 'depc 0.88', value: 'depc 0.88' },
    { label: 'depc 0.89', value: 'depc 0.89' },
    { label: 'depc 0.90', value: 'depc 0.90' },
    { label: 'depc 0.91', value: 'depc 0.91' },
    { label: 'depc 0.92', value: 'depc 0.92' },
    { label: 'depc 0.93', value: 'depc 0.93' },
    { label: 'depc 0.94', value: 'depc 0.94' },
    { label: 'depc 0.95', value: 'depc 0.95' },
    { label: 'depc 0.96', value: 'depc 0.96' },
    { label: 'depc 0.97', value: 'depc 0.97' },
    { label: 'depc 0.98', value: 'depc 0.98' },
    { label: 'depc 0.99', value: 'depc 0.99' },
    { label: 'depc 1', value: 'depc 1' }
  ],
  CAMPAIGN_OBJECTIVES: [
    { label: 'Outcome sales', value: 'outcome_sales' },
    { label: 'Outcome traffic', value: 'outcome_traffic' }
  ],
  VIP_USERS_DOMAIN: 'brainye.com',
  VIP_PAGE: {
    default: false,
    label: 'Brain Fun',
    site_name: 'edk',
    value: '100786438719684',
    _id: '60224d1d51b06f652b23e9ba'
  },
  VIP_PAGE_ID: '100786438719684',
  VIP_CONVERSION_ID: '2937769159831839',
  DEFAULT_LIMIT: '(0 of 250)',
  GENDERS_LIST: [
    { label: 'All', value: 0 },
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 }
  ],
  AGE_LIST: lodash.range(13, 66).map(x => ({ label: x, value: x })),
  BUDGET_LIST: lodash.range(1, 1000).map(x => ({ label: x, value: x })),
  CAMPAIGN_BUDGET_LIST: lodash.range(15, 1000).map(x => ({ label: x, value: x })),
  ALL_SITES_ACCOUNT_LIST: [
    'act_748301549189121',
    'act_1106779289789841',
    'act_201316478222225',
    'act_192186905952319',
    'act_468566354230112',
    'act_788904185094159',
    'act_170647335039715',
    'act_163161772398978',
    'act_519596279193057'
  ],
  VIP_SITES_LIST: [
    'tdd',
    'oft',
    'efu',
    'wpd',
    'tsw',
    'ttb',
    'mdm',
    'tdc',
    'c4a',
    'fin',
    'fle',
    'fre',
    'nos',
    'vrg',
    'hab'
  ],
  VIP_CONVERSIONS_ACCOUNT_LIST: ['act_170647335039715', 'act_163161772398978', 'act_519596279193057'],
  XU_PREFIX_ACCOUNT_LIST: ['act_170647335039715', 'act_163161772398978', 'act_519596279193057'],
  FB_OPTIONS_NOTE_USERS: ['dina@shinez.io'],
  CHARS: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
  OLD_CONVERSIONS: [
    'all - 0.01',
    'all - 0.02',
    'all - 0.03',
    'all - 0.04',
    'all - 0.05',
    'all - 0.06',
    'all - 0.07',
    'all - 0.08',
    'all - 0.09',
    'all - 0.1',
    'all - 0.10',
    'all - 0.11',
    'all - 0.12',
    'all - 0.13',
    'all - 0.14',
    'all - 0.15',
    'all - 0.16',
    'all - 0.17',
    'all - 0.18',
    'all - 0.19',
    'all - 0.2',
    'all - 0.20',
    'all - 0.21',
    'all - 0.22',
    'all - 0.23',
    'all - 0.24',
    'all - 0.25',
    'all - 0.26',
    'all - 0.27',
    'all - 0.28',
    'all - 0.29',
    'all - 0.3',
    'all - 0.30',
    'all every value',
    'articles content',
    "partners's pixel",
    'depm',
    'depc',
    'decm',
    'pixel',
    'temp',
    'old'
  ],
  PAGE: ['273257481191104'],
  WEWORDS_BM_ID: '860787584329188',
  QUIZ_CONVERSIONS: 'WIZ',
  CONVS: {
    PURCHASE_CAPI: { label: 'Purchase (server-to-server)', value: 'capi_purchase' },
    PURCHASE: { label: 'Purchase (client)', value: 'purchase' },
    REVB: { label: 'Purchase REVB', value: 'capi_revb' }
  },
  THE_SAME_AS_ORIGINAL: 'THE_SAME_AS_ORIGINAL',
  LEON_USER: 'leon@shinez.io',
  KNOWN_URL_PARAMS: [{ label: 'no pixels', value: 'no_pixels=true' }],
  ADSET_LIMIT: 50,
  DYNAMIC_IMAGE_LIMIT: 10,
  SWAG_AD_TYPE: [
    { label: 'Single', value: 'SINGLE' },
    { label: 'Dynamic', value: 'DYNAMIC' },
    { label: 'Single video', value: 'SINGLE_VIDEO' }
  ],
  AD_TYPE: [
    { label: 'Single', value: 'SINGLE' },
    { label: 'Dynamic', value: 'DYNAMIC' }
  ],
  STATUS: [
    { label: 'Active', value: 'active' },
    { label: 'Paused', value: 'paused' }
  ],
  LANGUAGE_UK: 24,
  LANGUAGE_US: 6,
  ERRORS: {
    SINGLE_ADSET: 'SINGLE adset should have at least one ad',
    MISSED_WIDGET_ID: 'Empty widget id',
    MISSED_DESTINATION_ACCOUT: 'Empty destination account',
    BLOCKED_CREATIVE_FOR_BM_ID: `Ads are blocked for the selected account (business ID). Please choose another account`,
    INVALID_OBJECTIVE: 'Outcome traffic campaign objective forbidden with Lowest min ROAS bid strategy',
    BLOCKED_ARTICLE: 'You are trying to use blocked article. Please choose another article',
    INVALID_GEO_WITH_TYPE: '  Geo worldwide is invalid with type exclude',
    INVALID_GEO_GB: 'Geo uk is invalid, only gb can be used',
    INVALID_AGE:
      'Increase the minimum age of your audience to 18 globally, 20 in Thailand or 21 in Indonesia or remove all targeting options apart from location, age and gender',
    EMPTY_DOMAIN: 'Select device',
    EMPTY_DEVICE: 'Select device',
    EMPTY_GEO: 'Select country',
    EMPTY_CC: 'Select at least one cc',
    INVALID_PLATFORM: 'Select at least one platform',
    LOW_ADSET_BID_AMOUNT: 'Adset bid amount must be greater than 0',
    LOW_CAMPAIGN_BID_AMOUNT: 'Campaign bid amount must be greater than 0',
    EMPTY_CAMPAIGN_NOTE: 'Empty campaign note',
    BUDGET_END_TIME: 'Select budget end time',
    TITLE_LENGTH: 'title - is longer than 255 bytes',
    POPULATED_TEXT: "SINGLE adset's ad should have all text fields populated",
    DUPLICATE_ADS: "SINGLE adset's ads should not have the same image, text and title.",
    SENSATIONAL: `You're trying to use a sensational words.`
  },
  MIN_BUDGET: 5
};

export const TASKS = {
  CREATE: 'Create',
  DUPLICATE: 'Duplicate'
};

export const PLATFORMS = {
  BAIDU: 'baidu',
  TABOOLA: 'taboola',
  REVCONTENT: 'revcontent',
  FACEBOOK: 'facebook',
  FACEBOOK2: 'facebook2',
  FACEBOOK_COPY: 'facebook_copy',
  FACEBOOK_BULK: 'facebook_bulk',
  FACEBOOK_UPLOAD_CREATIVES: 'facebook_upload_creatives',
  FACEBOOK_REVIEW_CREATIVES: 'facebook_review_creatives',
  OUTBRAIN: 'outbrain',
  TWITTER: 'twitter',
  TWITTER_BULK: 'twitter_bulk',
  GOOGLE_ADS: 'google_ads',
  ZEMANTA: 'zemanta',
  TABOOLA_BULK: 'taboola_bulk',
  PINTEREST: 'pinterest'
};

export const PLATFORM_OPTIONS = [
  { label: 'Baidu', value: 'baidu' },
  { label: 'Taboola', value: 'taboola' },
  { label: 'Revcontent', value: 'revcontent' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'Facebook2', value: 'facebook2' },
  { label: 'Facebook Copy', value: 'facebook_copy' },
  { label: 'Facebook Bulk', value: 'facebook_bulk' },
  { label: 'Facebook Upload Creatives', value: 'facebook_upload_creatives' },
  { label: 'Facebook Review Creatives', value: 'facebook_review_creatives' },
  { label: 'Outbrain', value: 'outbrain' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'Twitter Bulk', value: 'twitter_bulk' },
  { label: 'Taboola Bulk', value: 'taboola_bulk' },
  { label: 'Google Ads', value: 'google_ads' },
  { label: 'Zemanta', value: 'zemanta' },
  { label: 'Pinterest', value: 'pinterest' }
];

export const COMMANDS = {
  LOG: 'log',
  INIT_SESSION: 'init_session',
  RECONNECT: 'reconnect',
  START_READ: 'start_read',
  CREATE_CAMPAIGNS: 'create_campaigns',
  DUPLICATE_CAMPAIGN: 'duplicate_campaign',
  END: 'end',
  SOURCE_DATA: 'source_data',
  CREATIVES: 'creatives',
  PROGRESS: 'progress',
  TRACE: 'trace',
  TRACES: 'traces',
  UPDATE_CAMPAIGNS: 'update_campaigns',
  WRITE_HISTORY: 'write_history',
  GET_CAMPAIGNS: 'get_campaigns',
  FETCH_CAMPAIGNS: 'fetch_campaigns',
  FETCH_BULK_CAMPAIGNS: 'fetch_bulk_campaigns',
  LOAD_BULK_CAMPAIGNS: 'load_bulk_campaigns',
  LOAD_CAMPAIGN: 'load_campaign',
  FETCH_CAMPAIGNS_STRUCTURE: 'fetch_campaigns_structure',
  COPY_CAMPAIGN: 'copy_campaign',
  UPSERT_SNAPSHOT: 'upsert_snapshot'
};

export const GLOBAL = {
  APP_COUNTRY_LIST:
    'us,ca,de,gb,fr,es,mx,be,za,au,ch,nl,se,cl,sg,my,ar,at,ph,fi,br,no,ie,nz,dk,ae,tt,ro,pr,co,cr,gr,uy,it,hk,pt,id,hu,sa,in,pe,xx,pl,ke,jp,th,tw,hr,gt,ng,cz,ee,tr,pa,lv,ec,pk,sk,ve,ru,bg,cn,kw,mk,hn,ma,qa,lu,si,ua,il,is,kr,cy,eg,jm,cm,mt,re,do,bh,bn,vn,sv,om,et,lt,rs,lb,gh,bs,bb,bo,lk,tn,mu,ky,py,gu,dz,zm,bm,ni,jo,bd,mm,cw,zw,mq,gy,gp,na,ao,tz,vi,lc,ug,aw,fj,bz,bw,ag,mv,pg,ci,kh,np,iq,mz,gf,ge,ba,mn,kz,ht,gd,sr,ws,sn,ga,mo,al,mw,tc,af,md,vc,kn,mp',
  CREATE_NEW: 'CREATE_NEW',
  KEY_WIDGET_CODE_NAME: 'widgetCodeName',
  KEY_WIDGET_LANGUAGE_CODE: 'widgetLanguageCode',
  KEY_WIDGET_VERSION: 'widgetVersion',
  KEY_DESTINATION_ACCOUNT: 'destinationAccount',
  KEY_DESTINATION_SUB_ACCOUNT: 'destinationSubAccount',
  KEY_CAMPAIGN_NOTE: 'campaignNote',
  TAGS: [
    { label: 'F-UA_Batch_S.1', value: 'F-UA_Batch_S.1' },
    { label: 'F-UA_Batch_S.2', value: 'F-UA_Batch_S.2' },
    { label: 'F-UA_Batch_S.3', value: 'F-UA_Batch_S.3' },
    { label: 'F-UA_Clicks_S.1', value: 'F-UA_Clicks_S.1' },
    { label: 'F-UA_Batch_S.3_1.1-1.25', value: 'F-UA_Batch_S.3_1.1-1.25' },
    { label: 'F-UA_BadRoi.2Days', value: 'F-UA_BadRoi.2Days' },
    { label: 'F-UA_BadRoi.3Hrs', value: 'F-UA_BadRoi.3Hrs' },
    { label: 'F-UA_Batch_S.3_1-1.1', value: 'F-UA_Batch_S.3_1-1.1' },
    { label: 'F-UA_Batch_S.3_1.25-1.35', value: 'F-UA_Batch_S.3_1.25-1.35' },
    { label: 'F-UA_Batch_S.3_1.35-1.45', value: 'F-UA_Batch_S.3_1.35-1.45' },
    { label: 'F-UA_Batch_S.3_1.45-1.55', value: 'F-UA_Batch_S.3_1.45-1.55' },
    { label: 'F-UA_Batch_S.3_1.55-1.70', value: 'F-UA_Batch_S.3_1.55-1.70' },
    { label: 'F-UA_Batch_S.3_1.70-1.90', value: 'F-UA_Batch_S.3_1.70-1.90' },
    { label: 'F-UA_Batch_S.3_1.90+', value: 'F-UA_Batch_S.3_1.90+' },
    { label: 'S2S', value: 'S2S' },
    { label: 'SPICY', value: 'SPICY' },
    { label: 'OP', value: 'OP' },
    { label: 'Canva', value: 'Canva' },
    { label: 'comp', value: 'comp' },
    { label: 'mc', value: 'mc' },
    { label: 'op', value: 'op' },
    { label: 'bf', value: 'bf' },
    { label: 'mj', value: 'mj' },
    { label: 'mix', value: 'mix' },
    { label: 'Generate_S1', value: 'Generate_S1' }
  ],
  TAG: 'manual_menifa',
  SHINEZ_DOMAIN: 'shinez.io',
  FACEBOOK_PLATFORMS: [
    'facebook',
    'facebook2',
    'facebook_copy',
    'facebook_bulk',
    'facebook_upload_creatives',
    'facebook_review_creatives'
  ],
  ERRORS: {
    MISSED_WIDGET_ID: 'Empty widget id',
    MISSED_DESTINATION_ACCOUT: 'Empty destination account',
    INVALID_OBJECTIVE: 'Outcome traffic campaign objective forbidden with Lowest min ROAS bid strategy',
    BLOCKED_ARTICLE: 'You are trying to use blocked article. Please choose another article',
    EMPTY_CREATIVE_GROUPS: 'Empty creative groups',
    EMPTY_CREATOR: 'Empty creator',
    EMPTY_ARTICLE: 'Empty article'
  }
};

export const WIDGET_SITE_FILTERS = {
  mim: {
    sites: ['nmi', 'tup']
  }
};

export const REVIEWER = {
  DEMO_WW: 'demo@we-words.com',
  DEMO_YJ: 'demo@yj-marketing-services.com'
};

export const PRESET_OPTIONS = [
  { label: 'objective', value: 'campaignObjective' },
  { label: 'destination account', value: 'destinationAccount' },
  { label: 'domain', value: 'site_code' },
  { label: 'device', value: 'device' },
  { label: 'cc', value: 'cc' },
  { label: 'geo', value: 'countries' },
  { label: 'bid strategy', value: 'bid_strategy' },
  { label: 'budget type', value: 'budgetType' },
  { label: 'budget', value: 'budget' },
  { label: 'bid amount', value: 'bid_amount' },
  { label: 'start date', value: 'start_date' },
  { label: 'end date', value: 'end_date' }
];

export const NW = {
  NEGATIVE_WORDS_LIST_FB: 'NEGATIVE_WORDS_LIST_FB',
  NEGATIVE_WORDS_EXACT_MATCH_LIST: 'NEGATIVE_WORDS_EXACT_MATCH_LIST'
};
