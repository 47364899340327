import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { PinterestBids } from './PinterestBids';
import DatePicker from 'react-datepicker';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';
import { confirmAlert } from 'react-confirm-alert';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Box,
  CircularProgress,
  Alert,
  AlertTitle,
  Paper,
  List,
  ListItem,
  ListItemText,
  Fade,
  Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { createPinterestCampaign, getPinterestBoards, getPnConversions, storeFiles } from '../Api';
import { getPlatformSites } from '../Sites';
import { GLOBAL, PN, PLATFORMS, TASKS } from '../Constants';
import WidgetSelector from '../WidgetSelector';
import { parseCampaignName } from '../NameParser';
import { toast } from 'react-toastify';
import CampaignsNote from '../CampaignsNote';
import {
  displayWarnings,
  getImageDimensions,
  truncateFileName,
  validateAndPrepareImage
} from '../utils/imageUploadUtils';
import Prefix from '../Prefix';
import {
  getFileHash,
  getMultiSelectValue,
  getNewCreatorValue,
  getOnlyValue,
  getSelectValue,
  makeSelectOptions
} from '../Shared';
import _ from 'lodash';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.background.default
}));

const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius
}));
const defaultOptions = {
  [PN.KEY_BUDGET_AMOUNT]: PN.DEFAULT_BUDGET,
  [PN.KEY_DEFAULT_BID]: '',
  [PN.KEY_CREATIVE_GROUPS]: [],
  [PN.KEY_BIDS]: [],
  [PN.KEY_SITE]: [],
  [PN.KEY_OS_CHOICES]: [PN.IOS, PN.ANDROID, PN.MACOS, PN.WINDOWS, PN.ALL],
  [PN.KEY_PREFIX]: '',
  [PN.KEY_COUNTRY]: ['us'],
  [PN.KEY_DEVICE]: [PN.DESKTOP, PN.MOBILE],
  [PN.KEY_OS_DUPLICATION_TYPE]: 'separate',
  [PN.KEY_DEVICE_DUPLICATION_TYPE]: 'separate',
  [PN.KEY_DESTINATION_ACCOUNT]: '',
  [PN.KEY_ACCOUNT_CODE]: ''
};
class PinterestCreate extends Component {
  constructor(props) {
    super(props);

    this.suggestedBidsOB = [];
    this.defaultState = this.getDefaultState(props);
    this.state = this.defaultState;
    this.isSwag = props.programOptions.isSwag;
  }
  getDefaultState(props) {
    return {
      options: {
        ...defaultOptions,
        ...props.options,
        prefixes: [],
        prefix: '',
        boards: []
      },
      conversions: [],
      tooltips: { text: '', open: false },
      campaigns: [],
      triggerSelectorUpdate: false,
      device: [],
      destinationAccount: '',
      showCropWindow: false,
      language: [],
      country: [],
      os: []
    };
  }

  async componentDidMount() {
    await this.updateUserOptions();

    if (!this.props.loadedFromSnapshot) {
      this._modifyOptions(PN.KEY_BUDGET_AMOUNT, 10);
      this._modifyOptions(PN.KEY_CAMPAIGN_START_TIME, moment());
      if (this.state.options.prefix === 'xx') {
        this._modifyOptions(PN.KEY_STATUS, PN.INACTIVE);
      } else {
        this._modifyOptions(PN.KEY_STATUS, PN.ACTIVE);
      }
      this._modifyOptions(PN.KEY_BID_TYPE, 'AUTOMATIC');
    }
  }

  async updateUserOptions() {
    if (!this.props.loadedFromSnapshot) {
      const userOpts = this.props.programOptions;
      let prefix = userOpts?.spm_prefix_for_pinterest || userOpts.spm_prefix_default;
      this.modifyOptions(PN.KEY_CREATOR, getNewCreatorValue(userOpts.email, this.props.creatorsList));
      this.modifyOptions(PN.KEY_PREFIX, prefix);
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.sourceData !== prevProps.sourceData) {
      const newOptions = await this.getSourceData(
        this.state.options,
        this.props.sourceData,
        this.props.articleList,
        this.props.platform,
        this.props.creatorsList
      );
      this.setState(
        {
          options: newOptions,
          triggerSelectorUpdate: !this.state.triggerSelectorUpdate
        },
        () => {
          this.modifyBidsRows();
        }
      );
    }
    if (this.props.creativeFromFbLibrary) {
      this.props.creativeFbLibraryDone();
      console.log('creativeFromFbLibrary', this.props.creativeFromFbLibrary);
      this.addCreatives(this.props.creativeFromFbLibrary);
    }

    if (this.props.creative) {
      this.props.creativeDone();

      let parsedName;
      parsedName = parseCampaignName(PLATFORMS[this.props.creative.platform.toUpperCase()], this.props.creative.name);
      this.modifyOptions(PN.KEY_COUNTRY, parsedName.country || '');
      this.modifyOptions(PN.KEY_SITE, parsedName.site || []);

      this.modifyOptions('widgetCodeName', parsedName.widgetCodeName || '');
      this.modifyOptions('widgetLanguageCode', parsedName.widgetLanguageCode || '');
      this.modifyOptions('widgetVersion', 'shz');

      if (parsedName.platform === 'd') {
        this.modifyOptions(PN.KEY_DEVICE, PN.DESKTOP);
      } else if (parsedName.platform === 'a') {
        this.modifyOptions(PN.KEY_DEVICE, [PN.DESKTOP, PN.MOBILE, PN.TABLET].join(','));
      } else if (parsedName.platform === 'm') {
        this.modifyOptions(PN.KEY_DEVICE, PN.MOBILE);
      } else {
        if (parsedName.platform && parsedName.platform.indexOf('adr') !== -1) {
          this.modifyOptions(PN.KEY_OS, 'android');
          this.modifyOptions(PN.KEY_DEVICE, PN.MOBILE);
        }
        if (parsedName.platform && parsedName.platform.indexOf('ios') !== -1) {
          this.modifyOptions(PN.KEY_OS, 'ios');
          this.modifyOptions(PN.KEY_DEVICE, PN.MOBILE);
        }
      }

      if (typeof this.props.creative.image_url === 'string') {
        this.addCreatives([
          {
            images: [this.props.creative.image_url],
            texts: [this.props.creative.text],
            titles: [this.props.creative.title || ''],
            descriptions: [this.props.creative.text]
          }
        ]);
      } else {
        this.addCreatives([
          {
            images: this.props.creative.image_url,
            texts: [this.props.creative.text],
            titles: [this.props.creative.title || ''],
            descriptions: [this.props.creative.text]
          }
        ]);
      }
    }
  }

  async getSourceData(options, sourceData, articleList, platform, creatorsList) {
    let parsed = await parseDuplicateData(options, sourceData, articleList, platform, creatorsList);
    console.log({ parsed });
    return parsed;
  }

  getFullSite(domain) {
    const site = getPlatformSites(PLATFORMS.PINTEREST.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.name;
    }
    return undefined;
  }

  validateOptions(key) {
    const value = this.state.options[key];
    switch (key) {
      case PN.KEY_DEFAULT_BID:
        if (value < PN.MIN_BID) {
          console.log('Min bid reached');
          this._modifyOptions(key, PN.MIN_BID);
        } else if (value > PN.MAX_BID) {
          console.log('Max bid reached');
          this._modifyOptions(key, PN.MAX_BID);
        }
        break;
      case PN.KEY_BUDGET_AMOUNT:
        if (value < PN.MIN_BUDGET) {
          console.log('Min budget reached');
          this._modifyOptions(key, PN.MIN_BUDGET);
        }
        break;
      default:
        throw new Error('Unexpected key in validateOptions' + key);
    }
  }

  _modifyOptions(key, value) {
    const options = this.state.options;
    options[key] = value;
    this.setState({
      options: options
    });
  }

  addAdGroup = campaignKey => {
    const bids = [...this.state.options[PN.KEY_BIDS]];
    // Find the campaign by its key (country-device-os-site)
    const campaign = bids.find(bid => {
      const key = `${bid.country}-${bid.device.toLowerCase()}-${bid.os || ''}-${bid.site}`;
      console.log(key);
      console.log(campaignKey);
      return key === campaignKey;
    });
    if (!campaign) {
      console.error('Campaign not found');
      return;
    }
    const newAdGroup = {
      bid: '',
      pixel: '',
      creativeGroups: [{ images: [], titles: [], descriptions: [] }]
    };
    if (!campaign.adGroups) {
      campaign.adGroups = [];
    }
    campaign.adGroups.push(newAdGroup);
    this._modifyOptions(PN.KEY_BIDS, bids);
  };

  duplicateAdGroup = (campaign, adGroup) => {
    const bids = [...this.state.options[PN.KEY_BIDS]];
    let copiedAdGroup = _.cloneDeep(adGroup);
    campaign.adGroups.push(copiedAdGroup);
    this._modifyOptions(PN.KEY_BIDS, bids);
  };

  removeAdGroup = (campaignKey, adGroupIndex) => {
    const bids = [...this.state.options[PN.KEY_BIDS]];
    const campaign = bids.find(bid => {
      const key = `${bid.country}-${bid.device.toLowerCase()}-${bid.os || ''}-${bid.site}`;
      return key === campaignKey;
    });
    if (!campaign) {
      console.error('Campaign not found');
      return;
    }
    campaign.adGroups.splice(adGroupIndex, 1);
    if (campaign.adGroups.length === 0) {
      campaign.adGroups.push({
        bid: '',
        pixel: '',
        creativeGroups: [{ images: [], titles: [], descriptions: [] }]
      });
    }
    this._modifyOptions(PN.KEY_BIDS, bids);
  };

  modifyOptions(key, value) {
    if (key === PN.KEY_BUDGET_AMOUNT || key === PN.KEY_DAILY_CAP || key === PN.KEY_LIFETIME_CAP) {
      if (value.target) {
        value = parseFloat(value.target.value);
      }
    }
    if (key === PN.KEY_DEVICE && typeof value === 'string') {
      value = value.split(',');
    }

    this._modifyOptions(key, value);
    if (key === PN.KEY_DEVICE) {
      let devices = value;
      if (typeof devices === 'string') {
        devices = devices.split(',');
      }
      let newValue = [];
      if (
        !(
          devices.includes(PN.DESKTOP) &&
          (devices.includes(PN.MOBILE) || devices.includes(PN.TABLET)) &&
          this.state.options[PN.KEY_DEVICE_DUPLICATION_TYPE] === 'group'
        )
      ) {
        devices.forEach(dev => {
          if (PN.OS[dev]) {
            PN.OS[dev].forEach(os => {
              if (newValue.indexOf(os) === -1) {
                newValue.push(os);
              }
            });
          }
        });
      }
      this._modifyOptions(PN.KEY_OS_CHOICES, newValue);
      this._modifyOptions(
        PN.KEY_OS,
        newValue.filter(el => {
          return !PN.OS[PN.DESKTOP].includes(el);
        })
      );
    }
    if (
      key === PN.KEY_COUNTRY ||
      key === PN.KEY_DEVICE ||
      key === PN.KEY_OS ||
      key === PN.KEY_SITE ||
      key === PN.KEY_CONVERSION ||
      key === PN.KEY_DEVICE_DUPLICATION_TYPE ||
      key === PN.KEY_OS_DUPLICATION_TYPE ||
      key === GLOBAL.KEY_WIDGET_LANGUAGE_CODE
    ) {
      this.modifyBidsRows();
    }
    if (key === PN.KEY_DEFAULT_BID) {
      this.setDefaultBid(value);
    }
    if (key === PN.KEY_CAMPAIGN_START_TIME_RANGE) {
      let startDate = this.state.options[PN.KEY_CAMPAIGN_START_TIME];
      const endDate = moment(this.state.options[PN.KEY_CAMPAIGN_START_TIME_RANGE]).add(1, 'day');
      let startDates = [];
      while (startDate <= endDate) {
        startDates.push(startDate);
        startDate = moment(startDate).add(1, 'day');
      }
      this._modifyOptions(PN.KEY_CAMPAIGN_START_DATES, startDates);
    }
    if (key === PN.KEY_CAMPAIGN_STOP_TIME_RANGE) {
      let startDate = this.state.options[PN.KEY_CAMPAIGN_STOP_TIME];
      const endDate = moment(this.state.options[PN.KEY_CAMPAIGN_STOP_TIME_RANGE]).add(1, 'day');
      let startDates = [];
      while (startDate <= endDate) {
        startDates.push(startDate);
        startDate = moment(startDate).add(1, 'day');
      }
      this._modifyOptions(PN.KEY_CAMPAIGN_STOP_DATES, startDates);
    }
  }

  modifyBidsRows() {
    if (!this.state.options[PN.KEY_COUNTRY] || !this.state.options[PN.KEY_DEVICE] || !this.state.options[PN.KEY_SITE]) {
      return;
    }

    let geos = this.state.options[PN.KEY_COUNTRY];
    if (typeof geos === 'string') {
      geos = geos.split(',');
    }

    let devices = this.state.options[PN.KEY_DEVICE];
    if (typeof devices === 'string') {
      devices = devices.split(',');
    }

    if (this.state.options[PN.KEY_DEVICE_DUPLICATION_TYPE] === 'group') {
      devices = [devices.join(',')];
    }

    let oss = this.state.options[PN.KEY_OS] || [];
    if (typeof oss === 'string') {
      oss = oss.split(',');
    }

    if (this.state.options[PN.KEY_OS_DUPLICATION_TYPE] === 'group') {
      oss = [oss.join(',')];
    }

    let sites = this.state.options[PN.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }

    const oldBids = this.state.options[PN.KEY_BIDS] || [];
    let campaigns = [];

    geos.forEach(geo => {
      sites.forEach(site => {
        devices.forEach(dev => {
          const someOsMatch = oss.some(os => PN.OS[dev]?.includes(os));

          if (someOsMatch) {
            oss.forEach(os => {
              if (PN.OS[dev]?.includes(os)) {
                const campaign = {
                  country: geo,
                  device: dev,
                  site: site,
                  os: os,
                  fullSite: this.getFullSite(site),
                  conversion:
                    this.state.conversions?.find(el => el.value === this.state.options[PN.KEY_CONVERSION])?.id || '',
                  adGroups: [
                    {
                      bid: this.state.options[PN.KEY_DEFAULT_BID],
                      pixel: '',
                      creativeGroups: [{ images: [], titles: [], descriptions: [] }]
                    }
                  ]
                };
                campaigns.push(campaign);
              }
            });
          } else {
            const campaign = {
              country: geo,
              device: dev,
              site: site,
              fullSite: this.getFullSite(site),
              conversion:
                this.state.conversions?.find(el => el.value === this.state.options[PN.KEY_CONVERSION])?.id || '',
              adGroups: [
                {
                  bid: this.state.options[PN.KEY_DEFAULT_BID],
                  pixel: '',
                  creativeGroups: [{ images: [], titles: [], descriptions: [] }]
                }
              ]
            };
            campaigns.push(campaign);
          }
        });
      });
    });

    campaigns = campaigns.map(newCampaign => {
      const old = oldBids.find(
        el =>
          el.country === newCampaign.country &&
          el.device === newCampaign.device &&
          el.os === newCampaign.os &&
          el.site === newCampaign.site
      );

      if (old) {
        return {
          ...newCampaign,
          adGroups: old.adGroups || newCampaign.adGroups
        };
      }
      return newCampaign;
    });

    this._modifyOptions(PN.KEY_BIDS, campaigns);
  }

  modifyBid = (campaignKey, adGroupIndex, e, field = 'bid') => {
    const bids = [...this.state.options[PN.KEY_BIDS]];
    const campaign = bids.find(bid => {
      const key = `${bid.country}-${bid.device.toLowerCase()}-${bid.os || ''}-${bid.site}`;
      return key === campaignKey;
    });

    if (!campaign || !campaign.adGroups?.[adGroupIndex]) {
      console.error('Campaign or ad group not found');
      return;
    }

    const adGroup = campaign.adGroups[adGroupIndex];

    if (field === 'bid') {
      adGroup.bid = e.target.value;
      let warningBids = bids.flatMap(bid => bid.adGroups.filter(group => group.bid > PN.MAX_WARN_BID));
      this._modifyOptions('warnings', warningBids);
    } else if (field === 'pixel') {
      adGroup.pixel = e.target.value;
    }
    this._modifyOptions(PN.KEY_BIDS, bids);
  };

  validateBid = (campaignKey, adGroupIndex) => {
    const bids = [...this.state.options[PN.KEY_BIDS]];

    const campaign = bids.find(bid => {
      const key = `${bid.country}-${bid.device.toLowerCase()}-${bid.os || ''}-${bid.site}`;
      return key === campaignKey;
    });

    if (!campaign || !campaign.adGroups[adGroupIndex]) return;

    const adGroup = campaign.adGroups[adGroupIndex];
    if (adGroup.bid < PN.MIN_BID) {
      adGroup.bid = PN.MIN_BID;
    } else if (adGroup.bid > PN.MAX_BID) {
      adGroup.bid = PN.DEFAULT_BID;
    }

    this._modifyOptions(PN.KEY_BIDS, bids);
  };

  setDefaultBid(val) {
    let bids = this.state.options[PN.KEY_BIDS];
    bids.forEach(b => {
      b.bid = val;
      if (val < PN.MIN_BID) {
        b.bid = PN.MIN_BID;
      } else if (val > PN.MAX_BID) {
        b.bid = PN.DEFAULT_BID;
      }
    });
    this._modifyOptions(PN.KEY_BIDS, bids);
  }

  onAddImages = (campaignKey, adGroupIndex, groupIdx, blob) => {
    const bids = [...this.state.options[PN.KEY_BIDS]];
    const campaign = bids.find(bid => {
      const key = `${bid.country}-${bid.device.toLowerCase()}-${bid.os || ''}-${bid.site}`;
      return key === campaignKey;
    });

    if (!campaign) {
      console.error('Campaign not found for key:', campaignKey);
      return;
    }

    if (!(blob instanceof Blob)) {
      console.error('Invalid blob:', blob);
      return;
    }

    const images = [blob];
    const errs = [];
    const warnings = [];
    const formData = new FormData();
    let basicValidatedImages = [];

    return Promise.all(images.map(file => getImageDimensions(file)))
      .then(dimensions => {
        images.forEach((file, i) => {
          const fileToUse =
            file instanceof File ? file : new File([file], `image-${Date.now()}.jpg`, { type: 'image/jpeg' });
          const filename_truncated = truncateFileName(fileToUse.name);

          if (!validateAndPrepareImage(fileToUse, filename_truncated, dimensions[i], groupIdx, 'PN', warnings, errs)) {
            return;
          }

          formData.append(i, fileToUse);
          basicValidatedImages.push(fileToUse);
        });

        return Promise.all(basicValidatedImages.map(file => getFileHash(file)));
      })
      .then(() => {
        if (displayWarnings(errs, warnings)) return;
        return storeFiles(formData);
      })
      .then(filenames => {
        if (filenames) {
          if (!campaign.adGroups[adGroupIndex].creativeGroups) {
            campaign.adGroups[adGroupIndex].creativeGroups = [];
          }
          if (!campaign.adGroups[adGroupIndex].creativeGroups[groupIdx]) {
            campaign.adGroups[adGroupIndex].creativeGroups[groupIdx] = {
              images: [],
              titles: [],
              descriptions: []
            };
          }

          const creativeGroup = campaign.adGroups[adGroupIndex].creativeGroups[groupIdx];
          const newImageIndex = creativeGroup.images.length;

          creativeGroup.images[newImageIndex] = filenames[0];
          creativeGroup.titles[newImageIndex] = '';
          creativeGroup.descriptions[newImageIndex] = '';

          this._modifyOptions(PN.KEY_BIDS, bids);
        }
      })
      .catch(err => {
        console.log('Error in onAddImages:', err);
        Sentry.captureException(err);
      });
  };

  async addCreatives(xs, images) {
    let adset;
    const groups = this.state.options[PN.KEY_CREATIVE_GROUPS];

    adset = { images: [], titles: [] };
    for (const [adsetIndex, x] of xs.entries()) {
      for (const title of x.titles) {
        for (const [imageIndex] of x.images.entries()) {
          const [image] = images.filter(img => img.adsetIndex === adsetIndex && img.imageIndex === imageIndex);
          const nonEmpyCrops = Object.values(image.crops).filter(c => c !== null);
          for (const crop of nonEmpyCrops) {
            adset.images.push(crop);
            adset.titles.push(title);
          }
        }
      }
    }

    const newGroup = {
      ...adset,
      images: [],
      tooltips: []
    };

    this.modifyOptions(PN.KEY_CREATIVE_GROUPS, [...groups, newGroup]);
    setTimeout(async () => {
      images.forEach(image => {
        for (const type in image.crops) {
          this.onAddImages(0, image.crops[type].blob, image.crops[type].type, image.imageIndex);
        }
      });
    });
  }

  removeImage = (campaignKey, adGroupIndex, groupIdx, imageIdx) => {
    const bids = [...this.state.options[PN.KEY_BIDS]];
    const campaign = bids.find(bid => {
      const key = `${bid.country}-${bid.device.toLowerCase()}-${bid.os || ''}-${bid.site}`;
      return key === campaignKey;
    });

    if (!campaign?.adGroups?.[adGroupIndex]?.creativeGroups?.[groupIdx]) {
      console.error('Invalid campaign structure', { campaignKey, adGroupIndex, groupIdx });
      return;
    }

    const group = campaign.adGroups[adGroupIndex].creativeGroups[groupIdx];
    group.images.splice(imageIdx, 1);
    group.titles.splice(imageIdx, 1);
    group.descriptions.splice(imageIdx, 1);

    this._modifyOptions(PN.KEY_BIDS, bids);
  };

  duplicateImage = (campaignKey, adGroupIndex, groupIdx, imageIdx) => {
    const bids = [...this.state.options[PN.KEY_BIDS]];
    const campaign = bids.find(bid => {
      const key = `${bid.country}-${bid.device.toLowerCase()}-${bid.os || ''}-${bid.site}`;
      return key === campaignKey;
    });

    if (!campaign?.adGroups?.[adGroupIndex]?.creativeGroups?.[groupIdx]) {
      console.error('Invalid campaign structure', { campaignKey, adGroupIndex, groupIdx });
      return;
    }

    const group = campaign.adGroups[adGroupIndex].creativeGroups[groupIdx];
    group.images.push(group.images[imageIdx]);
    group.descriptions.push(group.descriptions[imageIdx]);
    group.titles.push(group.titles[imageIdx]);

    this._modifyOptions(PN.KEY_BIDS, bids);
  };

  populateText = (campaignKey, adGroupIndex, groupIdx, imageIdx) => {
    const bids = [...this.state.options[PN.KEY_BIDS]];
    const campaign = bids.find(bid => {
      const key = `${bid.country}-${bid.device.toLowerCase()}-${bid.os || ''}-${bid.site}`;
      return key === campaignKey;
    });
    if (!campaign?.adGroups?.[adGroupIndex]?.creativeGroups?.[groupIdx]) {
      return;
    }

    const group = campaign.adGroups[adGroupIndex].creativeGroups[groupIdx];
    const sourceDescription = group.descriptions[imageIdx];
    const sourceTitle = group.titles[imageIdx];

    group.images.forEach((_, idx) => {
      group.descriptions[idx] = sourceDescription;
      group.titles[idx] = sourceTitle;
    });

    this._modifyOptions(PN.KEY_BIDS, bids);
  };

  onChangeImageTitle = (campaignKey, adGroupIndex, groupIdx, imageIdx, value) => {
    const bids = [...this.state.options[PN.KEY_BIDS]];
    const campaign = bids.find(bid => {
      const key = `${bid.country}-${bid.device.toLowerCase()}-${bid.os || ''}-${bid.site}`;
      return key === campaignKey;
    });
    if (!campaign?.adGroups?.[adGroupIndex]?.creativeGroups?.[groupIdx]) {
      return;
    }

    campaign.adGroups[adGroupIndex].creativeGroups[groupIdx].titles[imageIdx] = value;
    this._modifyOptions(PN.KEY_BIDS, bids);
  };

  onChangeImageDescription = (campaignKey, adGroupIndex, groupIdx, imageIdx, value) => {
    const bids = [...this.state.options[PN.KEY_BIDS]];
    const campaign = bids.find(bid => {
      const key = `${bid.country}-${bid.device.toLowerCase()}-${bid.os || ''}-${bid.site}`;
      return key === campaignKey;
    });

    if (!campaign?.adGroups?.[adGroupIndex]?.creativeGroups?.[groupIdx]) {
      return;
    }

    campaign.adGroups[adGroupIndex].creativeGroups[groupIdx].descriptions[imageIdx] = value;
    this._modifyOptions(PN.KEY_BIDS, bids);
  };

  upsertSnapshot() {
    if (this.props.task === TASKS.CREATE) {
      this.props.upsertSnapshot(this.state.options);
    } else if (this.props.task === TASKS.DUPLICATE) {
      this.props.upsertSnapshot();
    }
  }

  async startCreateCampaign(options) {
    try {
      this.setState({ loading: true, error: null, success: false });

      const result = await createPinterestCampaign(options);
      console.log('Campaign creation result:', result);

      if (result.success) {
        this.setState({
          campaigns: result.campaigns,
          success: true,
          message: `Successfully created ${result.campaigns.length} campaigns`
        });
        return;
      }
      const errorParts = [];
      errorParts.push(result.error);
      if (result.cleanupStatus) {
        if (result.cleanupStatus.action === 'ARCHIVED') {
          errorParts.push(`Campaign "${result.cleanupStatus.campaignName}" was automatically archived`);
        } else if (result.cleanupStatus.action === 'FAILED_TO_CLEANUP') {
          errorParts.push(
            `Failed to archive campaign "${result.cleanupStatus.campaignName}"`,
            'Please check account and archive manually',
            result.cleanupStatus.details
          );
        }
      }

      if (result.apiError?.apiCode) {
        errorParts.push(`Error code: ${result.apiError.apiCode}`);
      }
      this.setState({
        error: errorParts.join('\n'),
        success: false
      });
    } catch (error) {
      console.error('Error in startCreateCampaign:', error);
      this.setState({
        error: error.message || 'Unexpected error during campaign creation',
        success: false
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  convertToUnixTimestamp = date => {
    return moment(date).unix();
  };

  formCreativeGroup(adsData) {
    return adsData[0].images.map((image, index) => ({
      image: image,
      title: adsData[0].titles[index] || '',
      text: adsData[0].descriptions[index] || ''
    }));
  }
  prepareCreateOptions(options) {
    let payload = {
      accountId: options.destinationAccount,
      accountCode: options.accountCode,
      widget_path: options.widget_path,
      widgetCodeName: options.widgetCodeName,
      widgetLanguageCode: options.widgetLanguageCode,
      widgetVersion: options.widgetVersion,
      widgetid: options.widgetid,
      objective_type: options.objective,
      start_time: this.convertToUnixTimestamp(options.campaign_start_time),
      prefix: options.prefix,
      objective: options.objective,
      budget: options.budget_type,
      budget_amount: options.budget_amount,
      bid_strategy: options.bid_strategy,
      bid_type: options.bid_type,
      targeting_strategy: options.targeting_strategy,
      conversion: options.conversion,
      status: options.status.toUpperCase(),
      article: options.fullCodeName,
      path: options.widget_path,
      osDuplicationType: options.osDuplicationType,
      deviceDuplicationType: options.deviceDuplicationType,
      creator: options.campaignCreator,
      free_text: options.freeText || '',
      note: options.campaignNote || '',
      board: options.board,
      __split: [
        options.bids.map(b => ({
          country: b.country,
          device: b.device,
          os: b.os && b.os[0] !== '' ? b.os : null,
          site: b.site,
          fullSiteName: b.fullSite,
          adGroups: b.adGroups.map(ad => ({
            ...ad,
            creativeGroups: this.formCreativeGroup(ad.creativeGroups)
          }))
        }))
      ]
    };
    if (options.budget_type === 'daily_spend_cap') {
      payload.daily_spend_cap = options.budget_amount;
    } else {
      payload.lifetime_spend_cap = options.budget_amount;
    }
    if (options.end_time && options.end_time.length > 0) {
      payload.end_time = this.convertToUnixTimestamp(moment(options.campaign_stop_time).toDate());
    }
    return payload;
  }

  getCampaignsNumber() {
    return this.state.options[PN.KEY_BIDS].length;
  }

  getCreateButtonText() {
    const campaignsNumber = this.getCampaignsNumber();

    const displayCampaignsNumber = campaignsNumber || '';
    switch (this.props.task) {
      case TASKS.CREATE:
        return `Create ${displayCampaignsNumber} campaigns`;
      case TASKS.DUPLICATE:
        return `Duplicate campaign to ${displayCampaignsNumber} new campaigns`;
      default:
        return 'Something is wrong';
    }
  }

  getAllowedWidgetVersions() {
    // If some selected domain allows only a subset of widget versions, return it instead of all allowed versions
    let sites = this.state.options[PN.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    for (const s of sites) {
      if (PN.SITE_ALLOWED_VERSIONS[s]) {
        return PN.SITE_ALLOWED_VERSIONS[s];
      }
    }
    return PN.ALLOWED_VERSIONS;
  }

  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site];
    return parts.filter(el => el).join('-') + ' = ' + bid.bid;
  }

  validateSetup() {
    if (!this.state.options[PN.KEY_DESTINATION_ACCOUNT]) {
      const err = 'select destination account';
      console.warn(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options[PN.KEY_PREFIX]) {
      const err = 'select prefix';
      console.warn(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options.widgetid) {
      const err = 'widget id is empty';
      console.warn(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options[PN.KEY_OBJECTIVE]) {
      const err = 'select objective';
      console.warn(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options[PN.KEY_BID_STRATEGY]) {
      const err = 'select bid strategy';
      console.warn(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options[PN.KEY_BUDGET_TYPE]) {
      const err = 'select budget';
      console.warn(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options[PN.KEY_CONVERSION]) {
      const err = 'select conversion';
      console.warn(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options[PN.KEY_BOARD]) {
      const err = 'select board';
      console.warn(err);
      toast.error(err);
      return false;
    }

    return true;
  }

  validateCampaign = options => {
    const errors = [];
    if (!options.__split || !Array.isArray(options.__split[0])) {
      errors.push('Invalid campaign structure');
      return errors;
    }
    const campaigns = options.__split[0];
    campaigns.forEach((campaign, campaignIndex) => {
      if (!campaign.adGroups || !Array.isArray(campaign.adGroups)) {
        errors.push(`Campaign ${campaignIndex + 1} has invalid ad groups structure`);
        return;
      }
      campaign.adGroups.forEach((adGroup, adGroupIndex) => {
        if (!adGroup.bid) {
          errors.push(`Campaign ${campaignIndex + 1}, Ad Group ${adGroupIndex + 1}: Bid is missing`);
        } else if (adGroup.bid === '0' || parseFloat(adGroup.bid) === 0) {
          errors.push(`Campaign ${campaignIndex + 1}, Ad Group ${adGroupIndex + 1}: Bid cannot be zero`);
        } else if (adGroup.bid > 50) {
          toast.info(`Campaign ${campaignIndex + 1}, Ad Group ${adGroupIndex + 1}: Bid is higher than 50`);
        }
        if (!adGroup.pixel) {
          errors.push(`Campaign ${campaignIndex + 1}, Ad Group ${adGroupIndex + 1}: Pixel is missing`);
        } else if (adGroup.pixel === '0' || parseFloat(adGroup.pixel) === 0) {
          errors.push(`Campaign ${campaignIndex + 1}, Ad Group ${adGroupIndex + 1}: Pixel cannot be zero`);
        } else if (adGroup.pixel > 0.3) {
          toast.info(`Campaign ${campaignIndex + 1}, Ad Group ${adGroupIndex + 1}: Pixel is higher than 0.3`);
        }
        if (!adGroup.creativeGroups || !adGroup.creativeGroups.length) {
          errors.push(`Campaign ${campaignIndex + 1}, Ad Group ${adGroupIndex + 1}: Creative groups are required`);
        } else {
          adGroup.creativeGroups.forEach((creative, creativeIndex) => {
            if (!creative.image || !creative.title || !creative.text) {
              errors.push(
                `Campaign ${campaignIndex + 1}, Ad Group ${adGroupIndex + 1}, Creative ${creativeIndex +
                  1}: Missing required creative fields`
              );
            }
          });
        }
      });
    });
    return errors;
  };

  async submit() {
    if (!this.validateSetup()) {
      return;
    }

    let options = this.prepareCreateOptions(this.state.options);
    console.log('prepared options:', options);
    const validationErrors = this.validateCampaign(options);
    console.log({ validationErrors });
    if (validationErrors.length > 0) {
      validationErrors.forEach(error => toast.error(error));
      return;
    }

    const totalCount = this.state.options.bids.length;
    console.log({ totalCount });

    confirmAlert({
      title: 'Warning',
      message: `Are you sure you want to create ${totalCount} campaigns?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.startCreateCampaign(options);
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  async updateDestinationAccount(acc) {
    const { accounts } = this.props;
    const selectedAccount = accounts.find(x => x.id === acc)?.id;
    const code = accounts.find(x => x.id === acc)?.code;
    const conversions = await getPnConversions(selectedAccount);
    const boards = await getPinterestBoards(selectedAccount);
    let preparedBoards;
    if (boards.length > 0) {
      preparedBoards = boards.map(x => ({ label: x.name, value: x.id }));
    }
    let cclist = Object.values(conversions).flatMap(array =>
      array.map(item => ({
        label: item.conversion_event,
        value: item.conversion_tag_id
      }))
    );
    this.modifyOptions(PN.KEY_DESTINATION_ACCOUNT, selectedAccount);
    this.modifyOptions(PN.KEY_ACCOUNT_CODE, code);
    this.modifyOptions('boards', preparedBoards);
    this.setState({
      conversions: cclist
    });
  }

  getDestinationAccountOptions() {
    return this.props.accounts
      .map(el => {
        return { label: el.name, value: el.id };
      })
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  render() {
    return (
      <>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Destination account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.updateDestinationAccount(x?.value)}
            options={this.getDestinationAccountOptions()}
            isClearable
            value={getSelectValue(this.getDestinationAccountOptions(), this.state.options.destinationAccount)}
          />
        </Grid>
        <Prefix
          value={{ label: this.state.options[PN.KEY_PREFIX], value: this.state.options[PN.KEY_PREFIX] }}
          onChange={x => this.modifyOptions(PN.KEY_PREFIX, x.value)}
          options={makeSelectOptions(this.props.programOptions?.prefix)}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Creator
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            value={getSelectValue(this.props.creatorsList, this.state.options[PN.KEY_CREATOR])}
            onChange={x => this.modifyOptions(PN.KEY_CREATOR, x.value)}
            options={this.props.creatorsList}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select geo
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(PN.KEY_COUNTRY, getOnlyValue(xs))}
            options={this.props.countryList}
            value={getMultiSelectValue(this.props.countryList, this.state.options[PN.KEY_COUNTRY])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select site
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(PN.KEY_SITE, getOnlyValue(xs))}
            options={this.props.siteList}
            value={getMultiSelectValue(this.props.siteList, this.state.options[PN.KEY_SITE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select device
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(PN.KEY_DEVICE, getOnlyValue(xs))}
            options={PN.DEVICES}
            value={getMultiSelectValue(PN.DEVICES, this.state.options[PN.KEY_DEVICE])}
          />
        </Grid>
        <Grid item xs={6} sm={3} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.options[PN.KEY_DEVICE_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) =>
                  this.modifyOptions(PN.KEY_DEVICE_DUPLICATION_TYPE, value ? 'group' : 'separate')
                }
                color="primary"
              />
            }
            label={
              this.state.options[PN.KEY_DEVICE_DUPLICATION_TYPE] === 'group' ? (
                <Typography gutterBottom variant={'subtitle2'}>
                  Group
                </Typography>
              ) : (
                <Typography gutterBottom variant={'subtitle2'}>
                  Separate
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select OS
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Select
            isClearable
            isMulti
            onChange={xs => this.modifyOptions(PN.KEY_OS, getOnlyValue(xs))}
            options={makeSelectOptions(this.state.options[PN.KEY_OS_CHOICES])}
            value={getMultiSelectValue(
              makeSelectOptions(this.state.options[PN.KEY_OS_CHOICES]),
              this.state.options[PN.KEY_OS]
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.options[PN.KEY_OS_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) => this.modifyOptions(PN.KEY_OS_DUPLICATION_TYPE, value ? 'group' : 'separate')}
                color="primary"
              />
            }
            label={
              this.state.options[PN.KEY_OS_DUPLICATION_TYPE] === 'group' ? (
                <Typography gutterBottom variant={'subtitle2'}>
                  Group
                </Typography>
              ) : (
                <Typography gutterBottom variant={'subtitle2'}>
                  Separate
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <WidgetSelector
          modify={(key, value) => this.modifyOptions(key, value)}
          articleList={this.props.articleList}
          options={this.state.options}
          allowedVersions={this.getAllowedWidgetVersions()}
          defaultVersion={PN.DEFAULT_VERSION}
          triggerSelectorUpdate={this.state.triggerSelectorUpdate}
          programOptions={this.props.programOptions}
        />
        {/*<Grid item xs={12} sm={4}>*/}
        {/*  <Typography gutterBottom variant={'subtitle2'}>*/}
        {/*    Targeting strategy*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} sm={8}>*/}
        {/*  <Select*/}
        {/*    onChange={x => this.modifyOptions(PN.KEY_TARGETING_STRATEGY, x?.value)}*/}
        {/*    options={PN.TARGETING_STRATEGY}*/}
        {/*    value={getSelectValue(PN.TARGETING_STRATEGY, this.state.options[PN.KEY_TARGETING_STRATEGY])}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant="subtitle2">
            Objective
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(PN.KEY_OBJECTIVE, x?.value)}
            options={PN.OBJECTIVE}
            value={getSelectValue(PN.OBJECTIVE, this.state.options[PN.KEY_OBJECTIVE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Bid strategy
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(PN.KEY_BID_STRATEGY, x?.value)}
            options={PN.BID_STRATEGY_TYPE}
            value={getSelectValue(PN.BID_STRATEGY_TYPE, this.state.options[PN.KEY_BID_STRATEGY])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant="subtitle2">
            Budget
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            onChange={x => this.modifyOptions(PN.KEY_BUDGET_TYPE, x?.value)}
            options={PN.BUDGET_TYPE}
            value={getSelectValue(PN.BUDGET_TYPE, this.state.options[PN.KEY_BUDGET_TYPE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <input
            type="number"
            name={PN.KEY_BUDGET_AMOUNT}
            min={PN.KEY_BUDGET_AMOUNT}
            value={this.state.options[PN.KEY_BUDGET_AMOUNT] || 0}
            onChange={value => this.modifyOptions(PN.KEY_BUDGET_AMOUNT, value)}
            onBlur={value => this.validateOptions(PN.KEY_BUDGET_AMOUNT, value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Conversions
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(PN.KEY_CONVERSION, x?.value)}
            options={this.state.conversions}
            value={getSelectValue(this.state.conversions, this.state.options[PN.KEY_CONVERSION])}
          />
        </Grid>
        <>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Campaign start date
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DatePicker
              startDate={this.state.options[PN.KEY_CAMPAIGN_START_TIME]}
              endDate={this.state.options[PN.KEY_CAMPAIGN_START_TIME_RANGE]}
              selected={
                this.state.options[PN.KEY_CAMPAIGN_START_TIME]
                  ? new Date(this.state.options[PN.KEY_CAMPAIGN_START_TIME])
                  : ''
              }
              onChange={value => this.modifyOptions(PN.KEY_CAMPAIGN_START_TIME, value)}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Campaign end date
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DatePicker
              selected={
                this.state.options[PN.KEY_CAMPAIGN_STOP_TIME]
                  ? new Date(this.state.options[PN.KEY_CAMPAIGN_STOP_TIME])
                  : ''
              }
              onChange={value => this.modifyOptions(PN.KEY_CAMPAIGN_STOP_TIME, value)}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              minDate={
                this.state.options[PN.KEY_CAMPAIGN_START_TIME]
                  ? moment(this.state.options[PN.KEY_CAMPAIGN_START_TIME])
                      .add(1, 'day')
                      .toDate()
                  : moment()
                      .add(1, 'day')
                      .toDate()
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant="subtitle2">
              Select board
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={x => this.modifyOptions(PN.KEY_BOARD, x?.value)}
              options={this.state.options.boards}
              value={getSelectValue(this.state.options.boards, this.state.options[PN.KEY_BOARD])}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={x => this.modifyOptions(PN.KEY_STATUS, x?.value)}
              options={PN.STATUS}
              value={getSelectValue(PN.STATUS, this.state.options[PN.KEY_STATUS])}
            />
          </Grid>

          <CampaignsNote
            value={this.state.options[GLOBAL.KEY_CAMPAIGN_NOTE]}
            onChange={e => {
              this._modifyOptions(GLOBAL.KEY_CAMPAIGN_NOTE, e.target.value);
            }}
          />
          <Grid item xs={12} sm={12} style={{ marginTop: '10px' }}>
            <PinterestBids
              bids={this.state.options[PN.KEY_BIDS]}
              modifyBid={this.modifyBid}
              validateBid={idx => this.validateBid(idx)}
              addAdGroup={this.addAdGroup}
              duplicateAdGroup={this.duplicateAdGroup}
              removeAdGroup={this.removeAdGroup}
              onAddImages={this.onAddImages}
              onChangeImageTitle={this.onChangeImageTitle}
              onChangeImageDescription={this.onChangeImageDescription}
              removeImage={this.removeImage}
              duplicateImage={this.duplicateImage}
              populateText={this.populateText}
            />
          </Grid>
        </>
        <Grid item xs={12} sm={4} align="right"></Grid>
        <Grid item xs={6} sm={4} align="right">
          <Button variant="outlined" fullWidth color="primary" onClick={() => this.upsertSnapshot()}>
            {this.props.loadedFromSnapshot ? 'Update' : 'Save'} settings
          </Button>
        </Grid>
        <Grid item xs={6} sm={4} align="right">
          <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
            {this.getCreateButtonText()}
          </Button>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <Fade in={this.state.loading}>
            <Box
              sx={{
                display: this.state.loading ? 'flex' : 'none',
                alignItems: 'center',
                gap: 2,
                mb: 2
              }}
            >
              <CircularProgress size={24} />
              <Typography variant="body1" color="text.secondary">
                Creating campaigns...
              </Typography>
            </Box>
          </Fade>
          {this.state.error && (
            <Alert severity="error" sx={{ mt: 2 }} variant="outlined">
              <AlertTitle>Error</AlertTitle>
              {this.state.error.split('\n').map((line, i) => (
                <Typography key={i} variant="body2" component="div" sx={{ mt: i > 0 ? 1 : 0 }}>
                  {line}
                </Typography>
              ))}
            </Alert>
          )}
          {this.state.success && (
            <Fade in={true}>
              <Box>
                <Alert severity="success" sx={{ mt: 2 }} variant="outlined">
                  <AlertTitle>Success</AlertTitle>
                  {this.state.message}
                </Alert>

                {this.state.campaigns && this.state.campaigns.length > 0 && (
                  <StyledPaper elevation={0} variant="outlined">
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Created Campaigns
                    </Typography>
                    <StyledList>
                      {this.state.campaigns.map((campaign, index) => (
                        <ListItem
                          key={campaign.id}
                          divider={index !== this.state.campaigns.length - 1}
                          sx={{
                            '&:hover': {
                              backgroundColor: 'action.hover'
                            }
                          }}
                        >
                          <ListItemText
                            primary={
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant="body1">{campaign.name}</Typography>
                                <Chip label={`ID: ${campaign.id}`} size="small" variant="outlined" sx={{ ml: 1 }} />
                              </Box>
                            }
                          />
                        </ListItem>
                      ))}
                    </StyledList>
                  </StyledPaper>
                )}
              </Box>
            </Fade>
          )}
        </Box>
      </>
    );
  }
}

function validateSourceData(sourceData) {
  const data = sourceData.campaignData;

  if (!data || !data.adGroups?.length) {
    console.log('Missing campaign data or ad groups');
    return null;
  }

  if (!data.adGroups[0].pins?.length) {
    console.log('No pins found in ad group');
    return null;
  }

  return data;
}

function getVal(data) {
  return {
    boardId: data.adGroups[0].pins[0]?.board_id,
    parsedName: parseCampaignName(PLATFORMS.PINTEREST, data.name)
  };
}

function setBasicOptions(options, parsedName, data) {
  options[GLOBAL.KEY_WIDGET_CODE_NAME] = parsedName.widgetCodeName;
  options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE] = parsedName.widgetLanguageCode;
  options[GLOBAL.KEY_CAMPAIGN_NOTE] = data.note;
  options[PN.KEY_CREATOR] = data.creator || options.campaignCreator;
  options[PN.KEY_SITE] = [parsedName.site];
  options[PN.KEY_COUNTRY] = [parsedName.country];
  options[PN.KEY_DEVICE] = [];
  options[PN.KEY_CREATIVE_GROUPS] = [];
  options[PN.KEY_BIDS] = [];
}

function setStrategy(options, data) {
  options[PN.KEY_BID_STRATEGY] = data.adGroups[0].bid_strategy_type;
  options[PN.KEY_BUDGET_TYPE] = data.adGroups[0].budget_type;
  options[PN.KEY_OBJECTIVE] = data.objective_type;
}

async function setupBoards(options, data, boardId) {
  try {
    const boards = await getPinterestBoards(data.ad_account_id);
    if (boards.length > 0) {
      options.boards = boards.map(x => ({ label: x.name, value: x.id }));
    }
    console.log('boards', boards);
    options[PN.KEY_BOARD] = boardId;
  } catch (error) {
    console.error('Error fetching Pinterest boards:', error);
  }
}

function setDevice(options, parsedName, data) {
  switch (parsedName.device) {
    case 'd':
      options[PN.KEY_DEVICE].push(PN.DESKTOP);
      break;
    case 'm':
      options[PN.KEY_DEVICE].push(PN.MOBILE);
      break;
    case 't':
      options[PN.KEY_DEVICE].push(PN.TABLET);
      break;
    case 'a':
      options[PN.KEY_DEVICE].push(PN.ALL);
      break;
    default:
      options[PN.KEY_DEVICE].push(null);
  }

  options[PN.KEY_OS_CHOICES] = PN.OS[parsedName.device];
  options[PN.KEY_BUDGET] = data.lifetime_spend_cap ? 'LIFETIME' : 'DAILY';
  options[PN.KEY_BUDGET_AMOUNT] = (data.lifetime_spend_cap || data.daily_spend_cap) / 1000000;
  options[PN.KEY_BID_TYPE] = 'AUTOMATIC';
  options[PN.KEY_OS] = data.os || '';
  options[PN.KEY_AUDIENCE] = null;
  if (options.prefix !== 'xx') {
    options[PN.KEY_STATUS] = data.status === 'ACTIVE' ? PN.ACTIVE : PN.INACTIVE;
  }
}

function processAdGroups(data, options, parsedName) {
  if (!data.adGroups?.length) return;

  const bid = bidPayload(parsedName, options);

  data.adGroups.forEach(adGroup => {
    if (!adGroup.pins?.length) return;

    const bidValue = getBidValue(adGroup);
    const pixelValue = getPixelValue(adGroup);

    const newAdGroup = {
      bid: bidValue,
      pixel: pixelValue,
      creativeGroups: [createCreativeGroup(adGroup.pins)]
    };

    bid.adGroups.push(newAdGroup);
  });

  if (bid.adGroups.length > 0) {
    bid.bid = bid.adGroups[0].bid;
    bid.pixel = bid.adGroups[0].pixel;
  }

  options[PN.KEY_BIDS] = [bid];
}

function bidPayload(parsedName, options) {
  return {
    country: parsedName.country,
    device: options[PN.KEY_DEVICE][0],
    site: parsedName.site,
    fullSite: getPlatformSites(PLATFORMS.PINTEREST.toLowerCase()).find(el => el.code === parsedName.site)?.name,
    conversion: '',
    adGroups: []
  };
}

function getBidValue(adGroup) {
  const cpaValue =
    adGroup.optimization_goal_metadata?.conversion_tag_v3_goal_metadata?.cpa_goal_value_in_micro_currency;
  return cpaValue ? (parseInt(cpaValue) / 1000000).toString() : PN.MIN_BID;
}

function getPixelValue(adGroup) {
  let pixelValue = '0.01';
  const pinWithLink = adGroup.pins.find(pin => pin.link && pin.link.includes('pixel_value='));
  if (pinWithLink) {
    try {
      const url = new URL(pinWithLink.link);
      pixelValue = url.searchParams.get('pixel_value') || '0.01';
    } catch {
      console.log('Error parsing pin URL for pixel value');
    }
  }
  return pixelValue;
}

function createCreativeGroup(pins) {
  return {
    images: pins.map(pin => pin.media?.images['1200x']?.url || ''),
    titles: pins.map(pin => pin.title || ''),
    descriptions: pins.map(pin => pin.description || '')
  };
}

async function parseDuplicateData(options, sourceData) {
  const data = validateSourceData(sourceData);
  if (!data) return;

  const { boardId, parsedName } = getVal(data);

  setBasicOptions(options, parsedName, data);
  setStrategy(options, data);
  await setupBoards(options, data, boardId);
  setDevice(options, parsedName, data);
  processAdGroups(data, options, parsedName);

  return options;
}
export { PinterestCreate };
